import SearchBar from "components/SearchBar";
import React, { useCallback, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Col,
  Row,
  Table,
  Spinner,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { postApi } from "redux/action/api_tbeams";

function CustomInput() {
  const [formData, setFormData] = useState({
    mda_name: "",
    mda_name_abbreviationName: "",
    office_title: "",
    office_title_abbreviation: "",
    id: 0,
  });

  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [del, setDel] = useState(false);

  const toggle = () => setOpen(!open);
  const toggleDel = () => setDel(!del);

  const { addToast } = useToasts();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  let type =
    formData.type === "update"
      ? "update"
      : formData.type === "delete"
      ? "delete"
      : "create";
  const getCCCopies = () => {
    setLoading(true);
    postApi(`post-cc?query_type=select`)
      .then((data) => {
        console.log("data", data);
        if (data.results) {
          // const arr = data.result.map((item) => ({
          //   ...item,
          // }));
          setTableData(data.results);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        addToast("Fail to Fetch", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    postApi(`post-cc?query_type=${type}&id=${formData.id}`, formData)
      .then((data) => {
        // console.log("data", data);
        if (data.success) {
          toggle();
          addToast("Form submitted successfully!", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          setIsLoading(false);
          setFormData({
            office_title: "",
            mda_name: "",
            mda_name_abbreviationName: "",
            office_title_abbreviation: "",
          });
          getCCCopies();
        }
      })
      .catch((err) => {
        console.log(err);
        addToast("An error occurred", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCCCopies();
  }, []);

  const [searchText, setSearchText] = useState("");

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    console.log("searchText", searchText);
  };

  const rows = [];
  tableData?.forEach((item) => {
    if (
      item.mdaName &&
      item.mdaName?.toLowerCase().indexOf(searchText?.toLowerCase()) === -1
    )
      return;
    else rows.push(item);
  });
  return (
    <div>
      <Modal isOpen={del} toggle={toggleDel} size="md" color="danger">
        <ModalBody>
          <div className="text-danger">Are you sure you want to delete</div>
          <b>{formData.mda_name}</b>
        </ModalBody>
        <center className="m-2">
          <Button
            color="success"
            variant="success"
            onClick={toggleDel}
            className="mx-2"
          >
            Cancel
          </Button>
          <Button color="danger" onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? <Spinner color="light" size="sm" /> : "Comfirm"}
          </Button>
        </center>
      </Modal>

      <Modal isOpen={open} toggle={toggle} size="md">
        <ModalHeader>
          <center>{formData.mda_name}</center>
        </ModalHeader>
        <ModalBody>
          <Form className="m-1">
            {/* {JSON.stringify(type)} */}
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="mdaName">MDA Name</Label>
                  <Input
                    id="mdaName"
                    type="text"
                    placeholder="MDA Name"
                    value={formData.mda_name}
                    name="mda_name"
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="mdaName">MDA Name Abbreviation</Label>
                  <Input
                    id="mdaNameAbbreviation"
                    type="text"
                    placeholder="MDA Name Abbreviation"
                    value={formData.mda_name_abbreviationName}
                    name="mda_name_abbreviationName"
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="officeTitle">Office Title</Label>
                  <Input
                    id="officeTitle"
                    type="text"
                    placeholder="Office Title"
                    value={formData.office_title}
                    name="office_title"
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label for="Office title">Office title Abbreviation</Label>
                  <Input
                    id="offivetitleAbbreviation"
                    type="text"
                    placeholder="offivetitleAbbreviation "
                    value={formData.office_title_abbreviation}
                    name="office_title_abbreviation"
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label for="Office title">Prefix</Label>
                  <Input
                    id="prefix"
                    type="text"
                    placeholder="prefix "
                    value={formData.prefix}
                    name="prefix"
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button
            color="warning"
            variant="warning"
            className="btn btn-warning"
            onClick={toggle}
          >
            cancel
          </Button>
          <Button color="success" onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? <Spinner color="light" size="sm" /> : "Submit"}
          </Button>
        </ModalFooter>
      </Modal>

      <SearchBar
        placeholder="Search By description"
        filterText={searchText}
        onChange={handleSearchChange}
      />
      <Table bordered className="mt-3">
        <thead>
          <tr>
            <td
              style={{
                fontSize: 15,
              }}
            >
              S/N
            </td>

            <td
              style={{
                fontSize: 15,
              }}
            >
              MDA Name
            </td>
            <td
              style={{
                fontSize: 15,
              }}
            >
              MDA NAME ABBREVIATION
            </td>
            <td
              style={{
                fontSize: 15,
              }}
            >
              Office Title
            </td>
            <td
              style={{
                fontSize: 15,
              }}
            >
              Office Title ABBREVIATION
            </td>
            <td
              style={{
                fontSize: 15,
              }}
            >
              Prefix
            </td>
            <td
              style={{
                fontSize: 15,
              }}
            >
              Action
            </td>
          </tr>
        </thead>
        {loading ? (
          <Spinner color="primary" />
        ) : (
          <tbody>
            {rows?.map((data) => (
              <tr key={data.id}>
                <td
                  style={{
                    fontSize: 15,
                  }}
                  scope="row"
                >
                  {data.id}
                </td>
                <td
                  style={{
                    fontSize: 15,
                  }}
                >
                  {data.mdaName}
                </td>
                <td
                  style={{
                    fontSize: 15,
                  }}
                >
                  {data.mdaNameAbbreviation}
                </td>
                <td
                  style={{
                    fontSize: 15,
                  }}
                >
                  {data.officeTitle}
                </td>
                <td
                  style={{
                    fontSize: 15,
                  }}
                >
                  {data.offivetitleAbbreviation}
                </td>
                <td
                  style={{
                    fontSize: 15,
                  }}
                >
                  {data.prefix}
                </td>
                <td
                  style={{
                    fontSize: 15,
                  }}
                  className="d-flex justify-content-around"
                >
                  <Button
                    size="sm"
                    color="success"
                    className="m-2"
                    style={{
                      fontSize: 15,
                    }}
                    onClick={() => {
                      toggle();
                      setFormData({
                        type: "update",
                        id: data.id,
                        office_title: data.officeTitle,
                        mda_name: data.mdaName,
                        mda_name_abbreviationName: data.mdaNameAbbreviation,
                        office_title_abbreviation: data.offivetitleAbbreviation,
                      });
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    size="sm"
                    color="danger"
                    className="m-2"
                    style={{
                      fontSize: 15,
                    }}
                    onClick={() => {
                      setFormData({
                        type: "delete",
                        id: parseInt(data.id),
                        mda_name: data.mdaName,
                      });
                      toggleDel();
                    }}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
    </div>
  );
}

export default CustomInput;
