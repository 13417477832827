/* eslint-disable react/jsx-no-duplicate-props */
import React, { useCallback, useEffect, useRef, useState } from "react";

// import { FaPlus } from "react-icons/fa";
// import { useNavigate } from "react-router-dom";

// import { AiFillEdit, AiFillEye } from "react-icons/ai";
// import { FiDelete, FiEdit } from "react-icons/fi";
import { getSector } from "helper";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import SearchBar from "components/SearchBar";
import CustomTable from "components/CustomTable";
import { _postApi } from "redux/action/api";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import { _fetchApi } from "redux/action/api";
import Scrollbar from "components/Tree/Scrollbar";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Spinner,
  Row,
  Col,
  Table,
  Badge,
  Alert,
  ButtonGroup,
} from "reactstrap";
import { formatNumber } from "redux/action/api";
import CustomTypeahead from "components/CustomTypeahead";
// import { today } from "helper";
import { year } from "helper";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useYear } from "useYearList";
import { checkIfNull } from "redux/action/api";
import { bg } from "redux/action/api";
import { FaEye } from "react-icons/fa";
import BudgetForm from "./BudgetForm";
import { BiTrash } from "react-icons/bi";
import { Edit } from "@mui/icons-material";
import ProposalTable from "./ProposalTable";
import { getNextYear } from "./getBudgetYear";
import { expiredSetting } from "examples/admin/helper";
import { getMDaType } from "helper";
import CommentModal from "./Comment";
import { ensureNumber } from "redux/action/api";

function Approved() {
  const years = useYear();
  const [form, setForm] = useState({
    budget_year: "",
    economic_code: "",
    economic_description: "",
    program_code: "",
    program_description: "",
    function_code: "",
    function_description: "",
    fund_code: "",
    fund_description: "",
    geo_code: "",
    geo_description: "",
    budget_amount: "",
    actual_amount: "",
    budget_type: "",
    start_date: "",
    end_date: "",
    segment: "",
    transaction_type: "",
    sector_type: "",
  });
  const [searchText, setSearchText] = useState("");
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const [modal3, setModal3] = useState(false);
  const toggle3 = () => setModal3(!modal3);
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const [editData, setEditData] = useState(null);

  const handleEditClick = (item) => {
    setEditData(item);
    toggleModal();
  };

  useEffect(() => {
    getNextYear((data) => setForm((p) => ({ ...p, budget_year: data })));
  }, []);

  const [huh, setHuh] = useState();

  const [modalOpen2, setModalOpen2] = useState(false);
  const toggleModal2 = () => {
    setModalOpen2(!modalOpen2);
  };

  const handleEditClick2 = (item, type) => {
    setEditData(item);
    toggleModal2();
    setHuh(type);
  };

  const [editingIndex, setEditingIndex] = useState(-1);
  const handleInputBlur = () => {
    setEditingIndex(-1);
    handleEditClick2(editData);
  };

  const handleDoubleClick = (index) => {
    setEditingIndex(index);
  };

  const fields = [
    {
      title: "Project/ Receipt Description",
      custom: true,
      component: (item) => <div>{item.description}</div>,
      rowSpan: true,
    },
    {
      title: "Administrative",
      custom: true,
      component: (item) => item.admin_description,
      rowSpan: true,
      width: 100,
    },
    {
      title: "Admin Code",
      custom: true,
      component: (item) => item.admin_code,
      rowSpan: true,
    },
    {
      title: "Economic Description",
      custom: true,
      component: (item) =>
        item.status === "final-house" ? (
          <a href="#" onClick={() => handleEditClick(item)}>
            {item.economic_description}
          </a>
        ) : (
          <div>{item.economic_description}</div>
        ),
      rowSpan: true,
    },
    {
      title: "Economic Code",
      custom: true,
      component: (item) =>
        item.status === "final-house" ? (
          <div onClick={() => handleEditClick(item)}>{item.economic_code}</div>
        ) : (
          <div>{item.economic_code}</div>
        ),
      rowSpan: true,
    },
    {
      title: "Program Description",
      custom: true,
      component: (item) =>
        item.status === "final-house" ? (
          <div onClick={() => handleEditClick(item)}>
            {item.program_description}
          </div>
        ) : (
          <div>{item.program_description}</div>
        ),
      rowSpan: true,
    },
    {
      title: "Functional Description",
      custom: true,
      component: (item) =>
        item.status === "final-house" ? (
          <div onClick={() => handleEditClick(item)}>
            {item.function_description}
          </div>
        ) : (
          <div>{item.function_description}</div>
        ),
      rowSpan: true,
    },
    {
      title: "Functional Code",
      custom: true,
      component: (item) =>
        item.status === "final-house" ? (
          <div onClick={() => handleEditClick(item)}>{item.function_code}</div>
        ) : (
          <div>{item.function_code}</div>
        ),
      rowSpan: true,
    },
    {
      title: "Geo Description",
      custom: true,
      component: (item) =>
        item.status === "final-house" ? (
          <div onClick={() => handleEditClick(item)}>
            {item.geo_description}
          </div>
        ) : (
          <div>{item.geo_description}</div>
        ),
      rowSpan: true,
    },
    {
      title: "Geo Code",
      custom: true,
      component: (item) =>
        item.status === "final-house" ? (
          <div onClick={() => handleEditClick(item)}>{item.geo_code}</div>
        ) : (
          <div>{item.geo_code}</div>
        ),
      rowSpan: true,
    },
    {
      title: "Fund Description",
      custom: true,
      component: (item) =>
        item.status === "final-house"
          ? item.fund_description
          : item.fund_description,
    },
    {
      title: "Fund Code",
      custom: true,
      component: (item) =>
        item.status === "final-house" ? item.fund_code : item.fund_code,
    },
    {
      title: "Fund Amount",
      custom: true,
      component: (item) => (
        <div style={{ textAlign: "right" }}>{formatNumber(item.fund_amt1)}</div>
      ),
    },

    {
      title: "Proposed Amount(₦)",
      custom: true,
      component: (item) => (
        <div style={{ float: "right" }}>{formatNumber(item.budget_amount)}</div>
      ),
      rowSpan: true,
    },
    {
      title: "MOPB Estimate(₦)",
      custom: true,
      component: (item) => (
        <div style={{ float: "right" }}>{formatNumber(item.mopb_amount)}</div>
      ),
      rowSpan: true,
    },
    {
      title: "Exco Estimate(₦)",
      custom: true,
      component: (item) => (
        <div style={{ float: "right" }}>{formatNumber(item.exco_amount)}</div>
      ),
      rowSpan: true,
    },
    {
      title: "House Estimate(₦)",
      custom: true,
      component: (item) => (
        <div style={{ float: "right" }}>{formatNumber(item.house_amount)}</div>
      ),
      rowSpan: true,
    },
    {
      title: "Approved Adjustments(₦)",
      custom: true,
      component: (item, idx) => (
        <div
          style={{ float: "right" }}
          onDoubleClick={() => handleDoubleClick(idx)}
        >
          {item.status === "final-house" && item.type_of_budget != "Capital" ? (
            editingIndex === idx ? (
              <Input
                name="approve_amount"
                value={item.approve_amount}
                onChange={({ target: { value } }) => handleChanges(value, idx)}
                onBlur={handleInputBlur}
                style={{ width: "120px", textAlign: "right" }}
              />
            ) : (
              formatNumber(item.approve_amount)
            )
          ) : (
            formatNumber(item.approve_amount)
          )}
        </div>
      ),
      rowSpan: true,
    },

    {
      title: "Action",
      custom: true,
      width: 200,
      component: (item) => (
        <div className="text-center d-flex flex-direction-row justify-content-between">
          {/* <button
            disabled={item.status === "draft" ? false : true}
            className="btn btn-danger btn-sm"
            color="primary"
            onClick={() => handleDelete(item.id)}
          >
            <BiTrash />
          </button> */}
          <Button
            // disabled={item.status === "draft" ? false : true}
            color="primary"
            onClick={() => handleEditClick2(item, "edit")}
            size="sm"
          >
            Show Comment
          </Button>
        </div>
      ),
      rowSpan: true,
    },
    // {
    //   title: "Budget Utilize(₦)",
    //   custom: true,
    //   component: (item) => (
    //     <div style={{ float: "right" }}>
    //       {formatNumber(item.actual_amount)}
    //     </div>
    //   ),
    //   rowSpan: true,
    // },

    // {
    //   title: "Action",
    //   custom: true,
    //   width: 200,
    //   component: (item) => (
    //     <div className="text-center d-flex flex-direction-row justify-content-between">
    //       <button
    //         disabled={item.status === "draft" ? false : true}
    //         className="btn btn-danger btn-sm"
    //         color="primary"
    //         onClick={() => handleDelete(item.id)}
    //       >
    //         <BiTrash />
    //       </button>
    //       <Button
    //         disabled={item.status === "draft" ? false : true}
    //         color="primary"
    //         onClick={() => handleEditClick(item)}
    //         size="sm"
    //       >
    //         <Edit />
    //       </Button>
    //     </div>
    //   ),
    //   rowSpan: true,
    // },
  ];

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);
  let query_type = form.transaction_type !== "" ? "budget_type" : "selects";
  // let data = results&&results.map(item => item.name)
  // budget_type;
  const getBudgetNew = useCallback(() => {
    setLoading(true);
    _fetchApi(
      `/get-budget-new/${form.mda_name}?query_type=approve&budget_year=${form.budget_year}&transaction_type=${form.transaction_type}`,
      (res) => {
        console.log(res.results, "DATA");
        const modifiedResults = res.results
          .map((item) => {
            if (
              item.approve_amount === 0 ||
              item.approve_amount === "" ||
              item.approve_amount === null
            ) {
              return { ...item, approve_amount: item.house_amount };
            }
            return item;
          })
          .filter(
            (item) =>
              item.status === "final-house" || item.status === "approved"
          );

        setResults(modifiedResults);
        console.log(modifiedResults, "DATA");

        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [form.mda_name, form.budget_year, form.transaction_type, query_type]);

  useEffect(() => {
    getBudgetNew();
    if (!modal3) {
      setForm((p) => ({ ...p, update: false }));
    }
  }, [getBudgetNew, modal3]);

  const [results5, setResults5] = useState([]);
  // let data = results&&results.map(item => item.name)
  useEffect(() => {
    getSector({ type: "ADMINISTRATIVE SEGMENT" }, "select", setResults5);
  }, []);

  const handleChanges = (value, index) => {
    const arr = [...results];
    arr[index].approve_amount = value;
    setResults(arr);
  };
  const { addToast } = useToasts();
  const [mda, setMda] = useState();
  const [rSelected, setRSelected] = useState(1);
  const [pastData, setPastData] = useState([]);
  const fetchPastProposeLength = useCallback(() => {
    getMDaType(
      { type: "", sub_head: form.budget_year },
      form.budget_year,
      (d) => {
        setPastData(d);
      },
      "approve"
    );
  }, [form.budget_year]);
  useEffect(() => {
    getMDaType(
      { type: "final-house", sub_head: form.budget_year },
      form.budget_year,
      (d) => {
        setMda(d);
        fetchPastProposeLength();
      },
      "type"
    );
  }, [form.budget_year]);
  let aheadData99 = mda?.map((i) => ({
    head: `${i.head}-${i.description}(${i.line_item})`,
    description: i.description,
    code: i.head,
  }));
  const handleSubmit = (name = "") => {
    const filteredResults = results.filter(
      (item) => item.status === "final-house"
    );
    let type = name === "approved" ? "UPDATE" : "INSERT";
    _postApi(
      `/post-budget?query_type=${type}&status=${name}`,
      name === "approved" ? filteredResults : [form],
      (res) => {
        if (res.success) {
          getBudgetNew();
          getMDaType(
            { type: "final-house", sub_head: form.budget_year },
            form.budget_year,
            (d) => {
              setMda(d);
              // alert(JSON.stringify(d))
            },
            "type"
          );
          fetchPastProposeLength();
          addToast("Submitted Successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          setData([]);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const handleUpdate = () => {
    _postApi(
      `/update-budget-new/${form.mda_name}?query_type=UPDATE&status=${form.status}&budget_year=${form.budget_year}&transaction_type=${form.transaction_type}`,
      form,
      (res) => {
        if (res.success) {
          getBudgetNew();
          addToast("Updated Successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          toggle3();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const [budget, setBudget] = useState([]);
  const getBudgetType = () => {
    setLoading(true);
    _fetchApi(
      `/get-budget-new/null?query_type=type&budget_type=${form.type}&budget_year=${form.year}`,
      (res) => {
        setBudget(res.results), setLoading(false);
      },
      (err) => console.log(err)
    );
  };
  useEffect(() => {
    getBudgetType();
  }, [form.type]);

  let data = [];

  results?.forEach((item) => {
    if (
      (item.economic_description &&
        item.economic_description
          ?.toLowerCase()
          .indexOf(searchText?.toLowerCase()) === -1) ||
      (item.economic_code &&
        item.economic_code?.toLowerCase().indexOf(searchText?.toLowerCase()) ===
          -1)
    )
      return;
    else data.push(item);
  });

  const filteredResults1 = data?.filter((item) => {
    if (!form.status || form.status === "All") {
      return true; // If no status is selected, or "All" is selected, return all items
    }
    return item.type_of_budget === form.status;
  });
  const filteredResults = results?.filter((item) => {
    if (!form.status || form.status === "All") {
      return item.status === "final-house";
    }
    return item.status === "final-house" && item.type_of_budget === form.status;
  });

  const totalAmount =
    rSelected === 1
      ? filteredResults?.reduce(
          (total, item) =>
            parseFloat(ensureNumber(item.approve_amount)) + total,
          0
        )
      : filteredResults1?.reduce(
          (total, item) => parseFloat(ensureNumber(item.house_amount)) + total,
          0
        );

  let aheadData = pastData?.map((i) => ({
    head: `${i.head}-${i.description}`,
    description: i.description,
    code: i.head,
  }));

  const repRef = useRef(null);
  const repRef1 = useRef(null);
  const repRef2 = useRef(null);
  const repRef3 = useRef(null);
  const repRef4 = useRef(null);
  const repRef5 = useRef(null);
  const mdaRef = useRef(null);

  const [results6, setResults6] = useState([]);
  const [results1, setResults1] = useState([]);
  const [results2, setResults2] = useState([]);
  const [results3, setResults3] = useState([]);
  const [results9, setResults9] = useState([]);
  const [results10, setResults10] = useState([]);
  // let data = results&&results.map(item => item.name)
  useEffect(() => {
    getSector({ type: "ADMINISTRATIVE SEGMENT" }, "select", setResults6);
  }, []);

  useEffect(() => {
    getSector({ type: "ECONOMIC SEGMENT" }, "select", setResults1);
  }, []);

  useEffect(() => {
    getSector({ type: "FUNCTIONAL SEGMENT" }, "select", setResults2);
  }, []);

  useEffect(() => {
    getSector({ type: "PROGRAMME SEGMENT" }, "select", setResults3);
  }, []);

  useEffect(() => {
    getSector({ type: "FUND SEGMENT" }, "select", setResults9);
  }, []);

  useEffect(() => {
    getSector({ type: "GEO SEGMENT" }, "select", setResults10);
  }, []);

  let aheadData6 =
    results9 &&
    results9?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData1 =
    results6 &&
    results6?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData2 =
    results2 &&
    results2?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData3 =
    results3 &&
    results3?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData4 =
    results1 &&
    results1?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData5 =
    results10 &&
    results10?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  const handleChanging = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const [approveData, setApproveData] = useState([]);
  const getApproveData = () => {
    _postApi(
      "/get-approval-by-date",
      {
        mda_name: form.mda_name,
        mda_code: form.mda_code,
        start_date: form.start_date,
        end_date: form.end_date,
      },
      (resp) => {
        // alert(JSON.stringify(resp));
        setApproveData(resp.results);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    getApproveData();
  }, [form.mda_name, form.mda_code, form.start_date, form.end_date]);

  const customLabelKey = (option) => `${option.description}`;
  const handleSave = (updatedItem) => {
    const updatedItems = results.map((item) =>
      item.id === updatedItem.id ? updatedItem : item
    );
    setResults(updatedItems);
    toggleModal();
  };
  let newBudget = { type_of_budget: "All" };
  let isObjectExists = budget?.some(
    (item) => item.type_of_budget === newBudget.type_of_budget
  );

  if (!isObjectExists) {
    budget?.push(newBudget);
  }
  const selectedResults = rSelected === 1 ? filteredResults : filteredResults1;

  const distinctResults = Array.from(
    new Map(
      selectedResults?.map((item) => [
        item.type_of_budget,
        { type_of_budget: item.type_of_budget },
      ])
    ).values()
  );
  return (
    <DashboardLayout>
      {form.mda_name && (
        <center>
          <h2>{/* {form.mda_code} - {form.mda_name} */}</h2>
        </center>
      )}
      <MDBox pt={1} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography
                  variant="h5"
                  color="white"
                  className="text-center"
                >
                  Approved Recommendation Page
                </MDTypography>
              </MDBox>

              <Modal isOpen={modal3} toggle={toggle3} size="lg">
                <ModalHeader>Continue With</ModalHeader>
                <ModalBody>
                  {/* {JSON.stringify(form)} */}
                  <div className=" row mb-4 p-3">
                    {/* <div className="col-md-6 ">
                      <Label>Select budget year</Label>
                      <Input
                        type="select"
                        name="budget_year"
                        value={form.budget_year}
                        onChange={handleChange}
                      >
                        <option>--select--</option>
                        {years?.map((item) => (
                          <option key={item}>{item}</option>
                        ))}
                      </Input>
                    </div> */}

                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef1}
                        labelKey={customLabelKey}
                        label="Economic Description"
                        options={aheadData4}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              economic_description: s[0].description,
                            }));
                          }
                        }}
                        defaultInputValue={form.economic_description}
                      />
                    </div>

                    <div className="col-md-6">
                      <small>Economic Code</small>
                      <input
                        className="form-control"
                        name="economic_code"
                        value={form.economic_code}
                        onChange={handleChanging}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef}
                        labelKey={customLabelKey}
                        label="Adminstrative Description"
                        options={aheadData1}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              admin_description: s[0].description,
                              mda_name: s[0].description,
                            }));
                          }
                        }}
                        defaultInputValue={form.admin_description}
                      />
                    </div>
                    <div className="col-md-6">
                      <small>Admin Code</small>
                      <input
                        className="form-control"
                        name="admin_code"
                        value={form.admin_code}
                        onChange={handleChanging}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef2}
                        labelKey={customLabelKey}
                        label="Fund Description"
                        options={aheadData6}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              fund_description: s[0].description,
                            }));
                          }
                        }}
                        defaultInputValue={form.fund_description}
                      />
                    </div>

                    <div className="col-md-6">
                      <small>Fund Code</small>
                      <input
                        className="form-control"
                        name="fund_code"
                        value={form.fund_code}
                        onChange={handleChanging}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef3}
                        labelKey={customLabelKey}
                        label="Geo Description"
                        options={aheadData5}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              geo_description: s[0].description,
                            }));
                          }
                        }}
                        defaultInputValue={form.geo_description}
                      />
                    </div>

                    <div className="col-md-6">
                      <small>Geo Code</small>
                      <input
                        className="form-control"
                        name="geo_code"
                        value={form.geo_code}
                        onChange={handleChanging}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef4}
                        labelKey={customLabelKey}
                        label="Program Description"
                        options={aheadData3}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              program_description: s[0].description,
                            }));
                          }
                        }}
                        defaultInputValue={form.program_description}
                      />
                    </div>
                    <div className="col-md-6">
                      <small>Program Code</small>
                      <input
                        className="form-control"
                        name="program_code"
                        value={form.program_code}
                        onChange={handleChanging}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef5}
                        labelKey={customLabelKey}
                        label="Functional Description"
                        options={aheadData2}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              function_description: s[0].description,
                            }));
                          }
                        }}
                        defaultInputValue={form.function_description}
                      />
                    </div>
                    <div className="col-md-6">
                      <small>Function Code</small>
                      <input
                        className="form-control"
                        name="function_code"
                        value={form.function_code}
                        onChange={handleChanging}
                      />
                    </div>
                    <div className="col-md-6">
                      <small>
                        Budget Amount ({formatNumber(form.budget_amount)})
                      </small>
                      <input
                        className="form-control"
                        name="budget_amount"
                        value={form.budget_amount}
                        onChange={handleChanging}
                      />
                    </div>
                    <div className="col-md-6">
                      <small>
                        Approved Amount ({formatNumber(form.approve_amount)})
                      </small>
                      <input
                        className="form-control"
                        type="number"
                        maxLength="4"
                        name="approve_amount"
                        value={form.approve_amount}
                        onChange={handleChanging}
                      />
                    </div>
                  </div>
                  <center>
                    <button
                      className="btn btn-success px-5 m-1"
                      onClick={() => {
                        if (form.update) {
                          handleUpdate();
                        } else {
                          handleSubmit("final-house");
                        }
                        toggle3();
                      }}
                    >
                      {form.update ? "Update" : "Submit"}
                    </button>

                    <button
                      className="btn btn-danger px-5 m-1"
                      onClick={() => {
                        toggle3();
                      }}
                    >
                      Close
                    </button>
                  </center>
                </ModalBody>
              </Modal>
              <MDBox>
                <MDBox pt={0}>
                  {editData && (
                    <BudgetForm
                      toggle={toggleModal}
                      toggle2={toggleModal2}
                      isOpen={modalOpen}
                      initialData={editData}
                      onSave={handleSave}
                      title={"Approve Recommendation Page"}
                      names="approve_amount"
                    />
                  )}

                  {editData && (
                    <CommentModal
                      toggle={toggleModal2}
                      isOpen={modalOpen2}
                      data={editData}
                      onSave={handleSave}
                      title={"approve Recommendation Form"}
                      names="approve recommendations"
                      edit={huh}
                    />
                  )}
                  <div className=" row p-3">
                    <Col md={4} className="mt-4">
                      <ButtonGroup size="sm">
                        <Button
                          color="primary"
                          outline
                          onClick={() => {
                            setRSelected(1);
                            mdaRef?.current?.clear();
                            setForm({ budget_year: form.budget_year });
                          }}
                          active={rSelected === 1}
                          size="sm"
                        >
                          House Adjustmented Proposals{" "}
                          <Badge color="warning">{mda?.length}</Badge>
                        </Button>
                        <Button
                          color="primary"
                          outline
                          onClick={() => {
                            setRSelected(2);
                            mdaRef?.current?.clear();
                            setForm({ budget_year: form.budget_year });
                          }}
                          active={rSelected === 2}
                        >
                          Final Approved
                          <Badge color="warning">{pastData?.length}</Badge>
                        </Button>
                      </ButtonGroup>
                    </Col>

                    <div className="col-md-2 mb-3">
                      <CustomTypeahead
                        labelKey="type_of_budget"
                        label="Budget type"
                        options={distinctResults || []}
                        onChange={(selected) => {
                          setForm((p) => ({
                            ...p,
                            status: selected[0]?.type_of_budget || "",
                          }));
                        }}
                        defaultSelected={[{ type_of_budget: "All" }]}
                      />
                    </div>
                    <div className="col-md-2">
                      <Label>Select budget year</Label>
                      <Input
                        type="select"
                        name="budget_year"
                        value={form.budget_year}
                        onChange={handleChange}
                      >
                        <option>--select--</option>
                        {years?.map((item) => (
                          <option key={item}>{item}</option>
                        ))}
                      </Input>
                    </div>
                    {/* {JSON.stringify({ form: form.transaction_type })} */}

                    <div className="col-md-3" style={{ marginTop: "25px" }}>
                      <Button
                        color="primary"
                        className="pr-5 pl-5"
                        onClick={() => toggle3()}
                        style={{ marginRight: 5 }}
                        disabled={filteredResults?.length === 0 ? true : false}
                      >
                        Add New Proposal
                      </Button>

                      <Button
                        color="primary"
                        className="pr-5 pl-5"
                        onClick={() => handleSubmit("approved")}
                        disabled={filteredResults?.length === 0 ? true : false}
                      >
                        Submit
                      </Button>
                    </div>

                    <div className="col-md-4 " style={{ marginTop: -20 }}>
                      <CustomTypeahead
                        labelKey="head"
                        label="Select MDA"
                        ref={mdaRef}
                        options={rSelected === 1 ? aheadData99 : aheadData}
                        onChange={(s) => {
                          if (s.length) {
                            console.log(s);
                            setForm((p) => ({
                              ...p,
                              mda_code: s[0].code,
                              mda_name: s[0].description,
                            }));
                          }
                        }}
                      />
                    </div>

                    <div className="col-md-4" style={{ marginTop: 10 }}>
                      <SearchBar
                        placeholder="Search By code or description"
                        // filterText={searchText}
                        // onFilterTextChange={handleSearchChange}
                      />
                    </div>

                    <div className="col-md-1"></div>
                    <div
                      className="col-md-3 text-right mt-4"
                      style={{ textAlign: "right" }}
                    >
                      <span style={{ fontWeight: "bold", float: "right" }}>
                        TOTAL : ₦{totalAmount?.toLocaleString()}
                      </span>
                    </div>
                    {loading && (
                      <div className="loading-container text-center">
                        <Spinner
                          color="primary"
                          style={{ marginTop: "20px" }}
                        />{" "}
                        Loading...
                      </div>
                    )}
                    {/* {JSON.stringify(data)} */}
                    <Scrollbar>
                      <ProposalTable
                        fields={fields}
                        data={
                          rSelected === 1 ? filteredResults : filteredResults1
                        }
                        type="approve"
                        loading={loading}
                      />
                    </Scrollbar>
                    <center>
                      <Modal
                        style={{ marginRight: "100px" }}
                        isOpen={modal}
                        toggle={toggle}
                        size="xl"
                      >
                        <ModalHeader>View Utilize Budget</ModalHeader>
                        <ModalBody>
                          {/* {JSON.stringify(approveData)} */}
                          <Row>
                            <Col md={6}>
                              <label>Date From</label>
                              <Input
                                name="start_date"
                                value={form.start_date}
                                onChange={handleChange}
                                type="date"
                              />
                            </Col>
                            <Col md={6}>
                              <label>Date To</label>
                              <Input
                                name="end_date"
                                value={form.end_date}
                                onChange={handleChange}
                                type="date"
                              />
                            </Col>
                          </Row>

                          <Table bordered striped className="mt-3">
                            <thead>
                              <tr>
                                <th style={{ width: "20%" }}>Approval Date</th>
                                <th style={{ width: "20%" }}>MDA Name</th>
                                <th style={{ width: "20%" }}>MDA Code</th>
                                <th style={{ width: "20%" }}>Economic Code</th>
                                <th style={{ width: "10%" }}>Amount</th>
                                <th style={{ width: "20%" }}>Approval Type</th>
                              </tr>
                            </thead>
                            <tbody>
                              {approveData &&
                                approveData[0]?.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.approval_date}</td>
                                    <td>{item.mda_name}</td>
                                    <td>{item.mda_name}</td>
                                    <td>{item.economic_code}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.approval_type}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </ModalBody>
                      </Modal>
                    </center>
                  </div>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Approved;
