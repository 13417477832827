import React, { useCallback, useEffect, useRef, useState } from "react";

// import { FaPlus } from "react-icons/fa";
// import { useNavigate } from "react-router-dom";

// import { AiFillEdit, AiFillEye } from "react-icons/ai";
// import { FiDelete, FiEdit } from "react-icons/fi";
import { getSector } from "helper";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import SearchBar from "components/SearchBar";
import CustomTable from "components/CustomTable";
import { _postApi } from "redux/action/api";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import { _fetchApi } from "redux/action/api";
import Scrollbar from "components/Tree/Scrollbar";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Spinner,
  Row,
  Col,
  Table,
  ButtonGroup,
} from "reactstrap";
import { formatNumber } from "redux/action/api";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";
import { postApi } from "redux/action/api_tbeams";

export default function MonthlyRecurrent() {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [form, setForm] = useState({});
  const [searchText, setSearchText] = useState("");
  const [modal, setModal] = useState(false);
  const [approveData, setApproveData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [selectedOption, setSelectedOption] = useState({
    value: "recurrent",
    title: " Monthly Recurrent",
  });

  const [loading, setLoading] = useState(false);
  //   let query_type = form.transaction_type !== "" ? "budget_type" : "selects";

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const getApproveData = useCallback(() => {
    setLoading(true);
    postApi(`monthly-recurrent?query_type=select`, {
      rec_type: selectedOption.value,
    })
      .then((res) => {
        if (res.success) {
          setLoading(false);
          setApproveData(res.results);
        }
      })
      .catch((err) => {
        alert(err);
        addToast(err, {
          appearance: "info",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedOption.value]);

  useEffect(() => {
    getApproveData();
  }, [getApproveData]);

  //   const handleDoubleClick = (index) => {
  //     setEditingIndex(index);
  //   };

  //   const handleInputBlur = () => {
  //     setEditingIndex(-1);
  //   };
  //   const handleChanges = (value, index) => {
  //     const arr = [...results];
  //     arr[index].approve_amount = value;
  //     setResults(arr);
  //   };

  let data = [];

  approveData?.forEach((item) => {
    if (
      item.admin_name &&
      item.admin_name?.toLowerCase().indexOf(searchText?.toLowerCase()) === -1
    )
      return;
    else data.push(item);
  });

  const dataByMDA = {};
  data?.forEach((item) => {
    if (Object.keys(dataByMDA)?.includes(item.admin_code)) {
      dataByMDA[item.admin_code] = [...dataByMDA[item.admin_code], item];
    } else {
      dataByMDA[item.admin_code] = [item];
    }
  });

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const options = [
    {
      value: "recurrent",
      title: " Monthly Recurrent",
    },
    {
      value: "critical",
      title: " Monthly Critical",
    },
  ];

  const handleButtonClick = (value, title) => {
    setSelectedOption({ value, title });
  };

  return (
    <DashboardLayout>
      <MDBox pt={1} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography
                  variant="h5"
                  color="white"
                  className="text-center"
                >
                  Monthly Recurrent Table
                </MDTypography>
              </MDBox>
              <div className="row" style={{ marginTop: 10, padding: 15 }}>
                <div className="col-md-4">
                  <Button
                    size="sm"
                    color="primary"
                    className=" mt-2 offset-1"
                    onClick={() =>
                      navigate(
                        `/recurrent-setup-form?type=${selectedOption.value}`
                      )
                    }
                    style={{ width: "100%", marginLeft: 0, marginButtom: 20 }}
                  >
                    Create New {selectedOption.title}
                  </Button>
                </div>
                <div className="col-md-4 mt-2">
                  <SearchBar
                    placeholder="Search By MDA Name"
                    filterText={searchText}
                    onFilterTextChange={handleSearchChange}
                  />
                </div>
                <div className="col-md-4 mt-2">
                  <ButtonGroup style={{ width: "100%" }}>
                    {options.map((item) => (
                      <Button
                        size="sm"
                        key={item.title}
                        outline={
                          selectedOption.title !== item.title ? true : false
                        }
                        color={
                          selectedOption.title === item.title
                            ? "success"
                            : "secondary"
                        }
                        onClick={() =>
                          handleButtonClick(item.value, item.title)
                        }
                      >
                        {item.title}
                      </Button>
                    ))}
                  </ButtonGroup>
                </div>
              </div>
              {/* {JSON.stringify(form)} */}
              <MDBox>
                <MDBox pt={0}>
                  <div className=" row p-3">
                    <Table
                      bordered
                      striped
                      className="mt-3"
                      size="sm"
                      style={{ fontSize: 12 }}
                      responsive
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "55%" }}>Description</th>
                          <th style={{ width: "20%" }}>Economic Code</th>
                          <th style={{ width: "15%" }}>Amount</th>
                          <th style={{ width: "10%" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(dataByMDA).map((group, grpIdx) => {
                          const total = dataByMDA[group].reduce(
                            (a, b) => a + parseFloat(b.amount),
                            0
                          );
                          return (
                            <React.Fragment key={grpIdx}>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {group}-{dataByMDA[group][0].admin_name}
                                </td>
                                <td></td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "right",
                                  }}
                                >
                                  N{formatNumber(total)}
                                </td>
                                <td></td>
                              </tr>
                              {dataByMDA[group].map((item, index) => (
                                <tr key={`${grpIdx}-${index}`}>
                                  <td>{item.economic_name}</td>
                                  <td>{item.economic_code}</td>
                                  <td
                                    style={{
                                      textAlign: "right",
                                    }}
                                  >
                                    {formatNumber(item.amount)}
                                  </td>
                                  <td
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Button
                                      size="sm"
                                      color="success"
                                      style={{ marginRight: 5 }}
                                      onClick={() => {
                                        setForm(item);
                                        setModal(true);
                                      }}
                                    >
                                      Edit
                                    </Button>
                                    <Button size="sm" color="danger">
                                      Del
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </React.Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
