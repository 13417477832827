import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FaEye, FaPen, FaPlus } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import CustomTree from "./CustomTree2";
import { toggleExpandedForAll } from "react-sortable-tree";
import SearchBar from "components/SearchBar";
import PrintWrapper from "components/PrintWrapper";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import { formatNumber } from "redux/action/api";
import useIsPhoneSize from "layouts/useIsPhoneSize";

function BudgetViewTree({ treeData = [] }) {
  const [searchText, setSearchText] = useState("");
  const [expanded, setExpanded] = useState(true);
  const [filteredTreeData, setFilteredTreeData] = useState(treeData);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const toggleExpandAll = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const expandedTreeData = treeData.map((node) => ({
      ...node,
      expanded: expanded,
    }));
    setFilteredTreeData(expandedTreeData);
  }, [expanded, treeData]);

  useEffect(() => {
    if (!searchText) {
      setFilteredTreeData(treeData);
    } else {
      const filterTree = (data) =>
        data
          .map((node) => {
            if (
              node.head?.toLowerCase().includes(searchText?.toLowerCase()) ||
              node.description
                ?.toLowerCase()
                .includes(searchText?.toLowerCase())
            ) {
              return {
                ...node,
                expanded: true,
                children: node.children ? filterTree(node.children) : [],
              };
            }
            return null;
          })
          .filter((node) => node !== null);
      const filteredData = filterTree(treeData);
      setFilteredTreeData(filteredData);
    }
  }, [searchText, treeData]);

  // Function to calculate the sum of budget amounts for a node and its children
  const calculateTotalBudgetAmount = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.total_budget_amount;
    } else {
      let sum = node.total_budget_amount || 0;
      for (const child of node.children) {
        sum += calculateTotalBudgetAmount(child);
      }
      return sum;
    }
  };

  // Modify generateNodeProps to display the total budget amount
  const generateNodeProps = ({ node, path }) => ({
    title: (
      <span>
        {`${node.head}-${node.description}-₦${formatNumber(
          calculateTotalBudgetAmount(node)
        )}`}
      </span>
    ),
    buttons: [],
  });

  const expand = (expanded) => {
    setFilteredTreeData(
      toggleExpandedForAll({
        treeData,
        expanded,
      })
    );
  };

  const expandAll = () => {
    expand(true);
  };

  const collapseAll = () => {
    expand(false);
  };

  const printTree = () => {
    window.frames["print_frame"].document.body.innerHTML =
      document.getElementById("print_tree").innerHTML;
    window.frames["print_frame"].window.focus();
    window.frames["print_frame"].window.print();
  };

  const isPhoneSize = useIsPhoneSize();

  return (
    <>
      <div className="row">
        <div className="col-md-4 ml-5 m-5 mb-1 mt-0">
          <SearchBar
            placeholder="Search By code or description"
            filterText={searchText}
            // onFilterTextChange={handleSearchChange}
          />
        </div>
        <div
          className={`col-md-6 ${isPhoneSize} ? d-flex justify-content-center align-items-center  : "" `}
        >
          <button
            onClick={expandAll}
            className="btn btn-primary sm-btn"
            style={{
              marginRight: "10px",
              marginLeft: "10px",
              padding: isPhoneSize ? "6px" : "",
            }}
          >
            Expand All
          </button>
          <button
            onClick={collapseAll}
            className="btn btn-primary sm-btn"
            style={{
              marginRight: "10px",
              marginLeft: "10px",
            }}
          >
            Collapse All
          </button>

          <button onClick={printTree} className="btn btn-primary sm-btn">
            Print Tree
          </button>
        </div>
      </div>
      <div id="print_tree">
        <PrintWrapper>
          <CustomTree
            generateNodeProps={generateNodeProps}
            treeData={filteredTreeData}
          />
        </PrintWrapper>
      </div>
      <iframe
        title="print_tree"
        name="print_frame"
        width="0"
        height="0"
        src="about:blank"
      />
    </>
  );
}

BudgetViewTree.propTypes = {
  editNode: PropTypes.func,
  deleteNode: PropTypes.func,
  addChild: PropTypes.func,
  more: PropTypes.func,
  treeData: PropTypes.array,
};

export default BudgetViewTree;
