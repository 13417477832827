import React, { useCallback, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { BsPrinter, BsXSquare } from "react-icons/bs";
import { getSector } from "helper";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import { _postApi } from "redux/action/api";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import { _fetchApi } from "redux/action/api";
import Scrollbar from "components/Tree/Scrollbar";
import { Button, Input, Label } from "reactstrap";
import { formatNumber } from "redux/action/api";
import { useSelector } from "react-redux";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { SiMicrosoftexcel } from "react-icons/si";
import { CSVLink } from "react-csv";
import { PDFViewer } from "@react-pdf/renderer";
import MyPDF from "./ProposedPdf";
import { useYear } from "useYearList";
import CustomTypeahead from "components/CustomTypeahead";
import BudgetForm from "./BudgetForm";
import ProposalTable from "./ProposalTable";
import { getBudgetYear, getNextYear } from "./getBudgetYear";
import { ensureNumber } from "redux/action/api";
import { FaTrash } from "react-icons/fa";

function Proposedtable() {
  const navigate = useNavigate();
  const budgetYear = getBudgetYear();
  const [editingIndex, setEditingIndex] = useState(-1);
  const years = useYear();
  const [form, setForm] = useState({
    year: "",
    status: "",
  });
  const handleDoubleClick = (index) => {
    setEditingIndex(index);
  };
  const [searchQuery, setSearchQuery] = useState("");

  const [results, setResults] = useState();
  const [loading, setLoading] = useState();
  const [result2, setResult2] = useState([]);
  const [budget, setBudget] = useState([]);
  const getBudgetType = () => {
    setLoading(true);
    _fetchApi(
      `/get-budget-new/null?query_type=type&budget_type=${form.type}&budget_year=${form.year}`,
      (res) => {
        setBudget(res.results), setLoading(false);
      },
      (err) => console.log(err)
    );
  };
  useEffect(() => {
    getBudgetType();
  }, [form.type]);

  useEffect(() => {
    getNextYear((data) => setForm((p) => ({ ...p, year: data })));
  }, []);

  useEffect(() => {
    getSector(
      { type: "mdas_approved", proposal_type: form.proposal_type_type },
      "mdas_approved",
      (d) => {
        setResult2(d);
      }
    );
  }, [form.proposal_type]);
  // let data = results&&results.map(item => item.name)
  let query_type =
    form.status === "" || form.status === "All" ? "SELECTS" : "btype";

  const user = useSelector((e) => e.auth.user);
  const getDamnData = useCallback(() => {
    _fetchApi(
      `/get-budget-new/${user?.mda}?query_type=SELECTS&budget_year=${form.year}&status=${form.status}`,
      (res) => setResults(res.results),
      (err) => console.log(err)
    );
  }, [form.year, form.status, query_type]);

  useEffect(() => {
    getDamnData();
  }, [getDamnData, form.status, query_type]);

  const handleInputBlur = () => {
    setEditingIndex(-1);
  };

  const handleDelete = (id) => {
    _postApi(
      `/post-budget?query_type=DELETE&status=DELETE`,
      [{ id: id }],
      (res) => {
        if (res.success) {
          addToast("Deleted successfully", {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          getDamnData();
        }
      },
      (err) => {
        console.log(err);
        // alert('an  error occured!!!!!!')
      }
    );
    getDamnData();
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [initialData, setInitialData] = useState([]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const [editData, setEditData] = useState(null);

  const handleEditClick = (item) => {
    setEditData(item);
    if (item) {
      toggleModal();
    }
  };

  const fields = [
    {
      title: "Project/ Receipt Description",
      custom: true,
      component: (item) => (
        <>
          {item.status === "draft" ? (
            <div onClick={() => handleEditClick(item)}>{item.description}</div>
          ) : (
            item.description
          )}
        </>
      ),
      rowSpan: true,
    },
    {
      title: "Administrative",
      custom: true,
      component: (item) => (
        <>
          {item.status === "draft" ? (
            <a href="#" onClick={() => handleEditClick(item)}>
              {item.admin_description}
            </a>
          ) : (
            item.admin_description
          )}
        </>
      ),
      width: 100,
      rowSpan: true,
    },
    {
      title: "Admin Code",
      custom: true,
      component: (item) => (
        <>
          {item.status === "draft" ? (
            <div onClick={() => handleEditClick(item)}>{item.admin_code}</div>
          ) : (
            item.admin_code
          )}
        </>
      ),
      rowSpan: true,
    },
    {
      title: "Economic Description",
      custom: true,
      component: (item) => (
        <>
          {item.status === "draft" ? (
            <div onClick={() => handleEditClick(item)}>
              {item.economic_description}
            </div>
          ) : (
            item.economic_description
          )}
        </>
      ),
      rowSpan: true,
    },
    {
      title: "Economic Code",
      custom: true,
      component: (item) => (
        <>
          {item.status === "draft" ? (
            <div onClick={() => handleEditClick(item)}>
              {item.economic_code}
            </div>
          ) : (
            item.economic_code
          )}
        </>
      ),
      rowSpan: true,
    },
    {
      title: "Program Description",
      custom: true,
      component: (item) => (
        <>
          {item.status === "draft" ? (
            <div onClick={() => handleEditClick(item)}>
              {item.program_description}
            </div>
          ) : (
            item.program_description
          )}
        </>
      ),
      rowSpan: true,
    },
    {
      title: "Program Code",
      custom: true,
      component: (item) => (
        <>
          {item.status === "draft" ? (
            <div onClick={() => handleEditClick(item)}>{item.program_code}</div>
          ) : (
            item.program_code
          )}
        </>
      ),
      rowSpan: true,
    },
    {
      title: "Functional Description",
      custom: true,
      component: (item) => (
        <>
          {item.status === "draft" ? (
            <div onClick={() => handleEditClick(item)}>
              {item.function_description}
            </div>
          ) : (
            item.function_description
          )}
        </>
      ),
      rowSpan: true,
    },
    {
      title: "Functional Code",
      custom: true,
      component: (item) => (
        <>
          {item.status === "draft" ? (
            <div onClick={() => handleEditClick(item)}>
              {item.function_code}
            </div>
          ) : (
            item.function_code
          )}
        </>
      ),
      rowSpan: true,
    },
    {
      title: "Geo Description",
      custom: true,
      component: (item) => (
        <>
          {item.status === "draft" ? (
            <div onClick={() => handleEditClick(item)}>
              {item.geo_description}
            </div>
          ) : (
            item.geo_description
          )}
        </>
      ),
      rowSpan: true,
    },
    {
      title: "Geo Code",
      custom: true,
      component: (item) => (
        <>
          {item.status === "draft" ? (
            <div onClick={() => handleEditClick(item)}>{item.geo_code}</div>
          ) : (
            item.geo_code
          )}
        </>
      ),
      rowSpan: true,
    },
    {
      title: "Filter by Budget type",
      custom: true,
      component: (item) => (
        <>
          {item.status === "draft" ? (
            <div onClick={() => handleEditClick(item)}>
              {item.type_of_budget}
            </div>
          ) : (
            item.type_of_budget
          )}
        </>
      ),
      rowSpan: true,
    },
    {
      title: "Fund Description",
      custom: true,
      component: (item) => (
        <>
          {item.status === "draft" ? (
            <div onClick={() => handleEditClick(item)}>
              {item.fund_description}
            </div>
          ) : (
            item.fund_description
          )}
        </>
      ),
    },
    {
      title: "Fund Code",
      custom: true,
      component: (item) => (
        <>
          {item.status === "draft" ? (
            <div onClick={() => handleEditClick(item)}>{item.fund_code}</div>
          ) : (
            item.fund_code
          )}
        </>
      ),
    },
    {
      title: "Fund Amount",
      custom: true,
      component: (item) => (
        <div style={{ textAlign: "right" }}>{formatNumber(item.fund_amt1)}</div>
      ),
    },
    {
      title: `${parseInt(form.year) - 2} Actual(₦)`,
      custom: true,
      component: (item) => (
        <div className="float-end" onClick={() => handleEditClick(item)}>
          {formatNumber(item.prev_year2_actual_amount)}
        </div>
      ),
      rowSpan: true,
    },
    {
      title: `${parseInt(form.year) - 1} Approved(₦)`,
      custom: true,
      component: (item) => (
        <div className="float-end" onClick={() => handleEditClick(item)}>
          {formatNumber(item.prev_year1_approve_amount)}
        </div>
      ),
      rowSpan: true,
    },
    {
      title: `${parseInt(form.year) - 1} Actual(₦)`,
      custom: true,
      component: (item) => (
        <div className="float-end" onClick={() => handleEditClick(item)}>
          {formatNumber(item.prev_year1_actual_amount)}
        </div>
      ),
      rowSpan: true,
    },
    {
      title: "Proposed Amount(₦)",
      custom: true,
      component: (item, idx) => (
        <div
          onDoubleClick={() => handleDoubleClick(idx)}
          style={{ float: "right" }}
        >
          {item.status === "draft" ? (
            editingIndex === idx ? (
              <Input
                name="budget_amount"
                value={item.budget_amount}
                onChange={({ target: { value } }) => handleChanges(value, idx)}
                onBlur={handleInputBlur}
                style={{ width: "120px", textAlign: "right" }}
              />
            ) : (
              <div className="float-end">
                {formatNumber(item.budget_amount)}
              </div>
            )
          ) : (
            <div className="float-end">{formatNumber(item.budget_amount)}</div>
          )}
        </div>
      ),
      rowSpan: true,
    },
    {
      title: `Delete`,
      custom: true,
      component: (item) =>
        item.status === "draft" ? (
          <Button
            disabled={item.status === "draft" ? false : true}
            size="sm"
            onClick={() => handleDelete(item.id)}
            color="danger"
          >
            <FaTrash />
          </Button>
        ) : null,
      rowSpan: true,
    },
  ];

  const handleChanges = (value, index) => {
    const arr = [...results];
    arr[index].budget_amount = value;
    setResults(arr);
  };
  const { addToast } = useToasts();

  const handleSubmit = () => {
    const filteredResults = results?.filter((item) => item.status === "draft");
    _postApi(
      `/post-budget?query_type=UPDATE&status=final-proposal`,
      filteredResults,
      (res) => {
        if (res.success) {
          addToast("Submit successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        }
        getDamnData();
      },
      (err) => {
        console.log(err);
        // alert('an  error occured!!!!!!')
      }
    );
  };
  const [results4, setResult4] = useState([]);

  useEffect(() => {
    getSector({ type: "mdas" }, "mdas", (d) => {
      setResult4(d);
    });
  }, []);
  const filteredResults1 = results?.filter((item) => {
    if (!form.status || form.status === "All") {
      return true; // If no status is selected, or "All" is selected, return all items
    }
    return item.type_of_budget === form.status;
  });

  const totalAmount = filteredResults1?.reduce(
    (total, item) => ensureNumber(item.budget_amount) + total,
    0
  );

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  let _expired = budgetYear[0]?.end_date;
  const submissionDeadline = moment(_expired);
  const daysLeft = submissionDeadline.diff(moment(), "days");
  const filteredResults = results?.filter((item) => item?.status === "draft");
  const [print, setPrint] = useState(false);

  let data = [];

  results?.forEach((item) => {
    if (
      (item.fund_description &&
        item.function_description
          ?.toLowerCase()
          .indexOf(searchQuery?.toLowerCase()) === -1) ||
      (item.fund_code &&
        item.fund_code?.toLowerCase().indexOf(searchQuery?.toLowerCase()) ===
          -1) ||
      (item.mda_name &&
        item.mda_name?.toLowerCase().indexOf(searchQuery?.toLowerCase()) === -1)
    )
      return;
    else data.push(item);
  });

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  let newBudget = { type_of_budget: "All" };
  let isObjectExists = budget?.some(
    (item) => item.type_of_budget === newBudget.type_of_budget
  );

  // If the object doesn't exist, push it into the array
  if (!isObjectExists) {
    budget?.push(newBudget);
  }

  const handleSave = (updatedItem) => {
    const updatedItems = results.map((item) =>
      item.id === updatedItem.id ? updatedItem : item
    );
    setResults(updatedItems);
    toggleModal();
  };

  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                my={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" gutterBottom color="white">
                  <center>
                    <h2 style={{ fontSize: "25px" }}>
                      {user?.mda + " proposals page"}
                    </h2>
                  </center>
                </MDTypography>
              </MDBox>
              {print ? (
                <div>
                  <Button color="danger" onClick={() => setPrint(!print)}>
                    cancel
                  </Button>
                  <br />
                  <PDFViewer width="1000" height="600">
                    {" "}
                    {/* Adjust the width and height as needed */}
                    <MyPDF data={data} />
                  </PDFViewer>
                </div>
              ) : (
                <MDBox>
                  {editData && (
                    <BudgetForm
                      toggle={toggleModal}
                      isOpen={modalOpen}
                      initialData={editData}
                      onSave={handleSave}
                      title={"MDA" + " proposals page"}
                      names="budget_amount"
                    />
                  )}
                  <div className="row">
                    <div className="col-md-10">
                      <center>
                        <div
                          className={
                            daysLeft < 20 ? "text-danger" : "text-success"
                          }
                          style={{ fontSize: "18px" }}
                        >
                          {daysLeft} {daysLeft === 1 ? "day" : "days"} left to
                          submit your budget proposal, Submission will close on{" "}
                          {submissionDeadline.format("Do MMMM, YYYY")}
                        </div>
                      </center>
                    </div>

                    <div className="col-md-2">
                      <Button color="success" className="m-2">
                        <CSVLink
                          data={results && results ? results : []}
                          className="text-white"
                          filename={`${user?.mda} ${form.year} budget proposal  `}
                        >
                          <SiMicrosoftexcel /> Download to Excel
                        </CSVLink>
                      </Button>
                    </div>
                  </div>

                  {/* {JSON.stringify(nextYear)} */}
                  <MDBox pt={3}>
                    <div className=" row mb-4 p-2">
                      {/* <div className="d-flex ">
                        <div className="col-md-6">
                          <button
                            className="btn btn-dark px-3 m-2"
                            onClick={() => navigate("/budget-form")}
                          >
                            Add New Item
                          </button>
                          <Button
                            color="primary"
                            className="px-5 m-2"
                            // style={{ float: "right" }}
                            onClick={handleSubmit}
                            disabled={
                              filteredResults?.length === 0 ? true : false
                            }
                          >
                            Submit
                          </Button>
                          <Button color="success" className="m-2">
                            <CSVLink
                              data={results && results ? results : []}
                              className="text-white"
                              filename={`${user?.mda} ${form.year} budget proposal  `}
                            >
                              <SiMicrosoftexcel /> Download to Excel
                            </CSVLink>
                          </Button>
                        </div>
                      </div> */}
                      {/* <div className="col-md-6 mb-3">
                        <CustomTypeahead
                          labelKey="name"
                          label="Filter by Budget type"
                          options={aheadData}
                          onChange={(selected) => {
                            setForm((p) => ({ ...p, type: selected[0]?.name }));
                          }}
                        />
                      </div> */}

                      <div className="col-md-2 mb-3 ">
                        <CustomTypeahead
                          labelKey="type_of_budget"
                          label="Filter by Budget type"
                          options={budget || []}
                          onChange={(selected) => {
                            setForm((p) => ({
                              ...p,
                              status: selected[0]?.type_of_budget,
                            }));
                          }}
                          defaultSelected={[{ type_of_budget: "All" }]}
                        />
                      </div>
                      <div className="col-md-2">
                        <Label>Select year</Label>
                        <Input
                          type="select"
                          name="year"
                          value={form.year}
                          onChange={handleChange}
                        >
                          <option>--select--</option>
                          {years?.map((item) => (
                            <option key={item}>{item}</option>
                          ))}
                        </Input>
                      </div>
                      <div className="col-md-4 mt-3">
                        <div className="d-flex justify-content-end">
                          {/* <div className="col-md-6"> */}
                          <button
                            className="btn btn-dark px-3 m-2"
                            onClick={() => navigate("/budget-form")}
                          >
                            Add New Item
                          </button>
                          <Button
                            color="primary"
                            className="px-5 m-2"
                            // style={{ float: "right" }}
                            onClick={handleSubmit}
                            disabled={
                              filteredResults?.length === 0 ? true : false
                            }
                          >
                            Submit
                          </Button>

                          {/* </div> */}
                        </div>
                      </div>

                      <div
                        className="col-md-4 text-right mt-4"
                        style={{ textAlign: "right" }}
                      >
                        <span
                          style={{ textAlign: "right", fontWeight: "bold" }}
                        >
                          TOTAL : ₦{totalAmount?.toLocaleString()}
                        </span>
                      </div>
                      {/* <div className="col-md-4">
                        <label>Search</label>
                        <SearchBar
                          placeholder="Search By fund code or description"
                          filterText={searchQuery}
                          onFilterTextChange={handleSearchChange}
                        />
                      </div> */}
                      {/* <div className="col-md-2">
                        <label>Year</label>
                        <Input
                          type="select"
                          name="year"
                          value={form.year}
                          onChange={handleChange}
                        >
                          <option>--select--</option>
                          {years?.map((item) => (
                            <option key={item}>{item}</option>
                          ))}
                        </Input>
                      </div> */}

                      {/*  <div className="col-md-4">
                        <SearchBar
                          placeholder="Search By fund code or descriptio
                          filterText={searchQuery}
                          onFilterTextChange={handleSearchChange}
                        />
                      </div> */}
                      {/* {JSON.stringify(parseInt(this_year) + 1)} */}
                      {/* <div className="col-md-4">
                        <button
                          className="btn btn-dark px-3"
                          onClick={() => navigate("/budget-form")}
                        >
                          Add New Item
                        </button>
                        <Button
                          color="primary"
                          className="px-5"
                          style={{ float: "right" }}
                          onClick={handleSubmit}
                          disabled={
                            filteredResults?.length === 0 ? true : false
                          }
                        >
                          Submit
                        </Button>
                      </div> */}

                      <div>
                        {/* <Button color="success" className="m-2">
                          <CSVLink
                            data={results && results ? results : []}
                            className="text-white"
                            filename={`${user?.mda} ${form.year} budget proposal  `}
                          >
                            <SiMicrosoftexcel /> Download to Excel
                          </CSVLink>
                        </Button> */}
                        {/* <Button color="success" onClick={printItemList}>
                          <BsPrinter /> Print
                        </Button> */}
                      </div>
                      <Scrollbar>
                        {/* {JSON.stringify(results[0])} */}

                        <ProposalTable
                          fields={fields}
                          data={filteredResults1}
                          type="proposals"
                        />
                      </Scrollbar>
                      {/* <center>
                        <Button
                          color="success"
                          className="pr-5 pl-5 btn btn-success"
                          onClick={handleSubmit}
                          disabled={
                            filteredResults?.length === 0 ? true : false
                          }
                        >
                          Submit
                        </Button>
                      </center> */}
                    </div>
                  </MDBox>
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Proposedtable;
