import React, { useCallback, useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { BsPrinter, BsXSquare } from "react-icons/bs";
import { getSector } from "helper";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import SearchBar from "components/SearchBar";
import CustomTable from "components/CustomTable";
import { _postApi } from "redux/action/api";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import { _fetchApi } from "redux/action/api";
import Scrollbar from "components/Tree/Scrollbar";
import { Button, Input, Label } from "reactstrap";
import { formatNumber } from "redux/action/api";
import { useSelector } from "react-redux";
import moment from "moment";
import { expiredSetting } from "examples/admin/helper";
import { useToasts } from "react-toast-notifications";
import { SiMicrosoftexcel } from "react-icons/si";
import { CSVLink } from "react-csv";
import { PDFViewer } from "@react-pdf/renderer";
import MyPDF from "./ProposedPdf";
import { useYear } from "useYearList";
import CustomTypeahead from "components/CustomTypeahead";
import { DownloadTableExcel } from "react-export-table-to-excel";

function BudgetBreakdown() {
  const navigate = useNavigate();
  const this_year = moment().format("YYYY");
  const [editingIndex, setEditingIndex] = useState(-1);
  const years = useYear();
  const [form, setForm] = useState({
    year: moment().format("YYYY"),
    status: "",
  });
  const handleDoubleClick = (index) => {
    setEditingIndex(index);
  };
  const [searchQuery, setSearchQuery] = useState("");

  const [results, setResults] = useState();
  const [loading, setLoading] = useState();
  const [result2, setResult2] = useState([]);
  const [budget, setBudget] = useState([]);
  const getBudgetType = () => {
    setLoading(true);
    _fetchApi(
      `/get-budget-new/null?query_type=type&budget_type=${form.type}&budget_year=${form.year}`,
      (res) => {
        setBudget(res.results), setLoading(false);
      },
      (err) => console.log(err)
    );
  };
  useEffect(() => {
    getBudgetType();
  }, [form.type]);

  useEffect(() => {
    getSector(
      { type: "mdas_approved", proposal_type: form.proposal_type_type },
      "mdas_approved",
      (d) => {
        setResult2(d);
      }
    );
  }, [form.proposal_type]);

  let aheadData = [
    {
      name: "Revenue",
    },
    {
      name: "Expenditure",
    },
  ];

  // let data = results&&results.map(item => item.name)
  let query_type =
    form.status === "" || form.status === "All" ? "SELECT" : "btype";

  const user = useSelector((e) => e.auth.user);
  const getDamnData = useCallback(() => {
    _fetchApi(
      `/get-budget-new/null?query_type=breakdown&budget_year=${form.year}&status=${form.status}`,
      (res) => setResults(res.results),
      (err) => console.log(err)
    );
  }, [form.year, form.status, query_type]);

  useEffect(() => {
    getDamnData();
  }, [getDamnData, form.status, query_type]);

  const handleInputBlur = () => {
    setEditingIndex(-1);
  };

  const handleDelete = (id) => {
    _postApi(
      `/post-budget?query_type=DELETE&status=DELETE`,
      [{ id: id }],
      (res) => {
        if (res.success) {
          addToast("Deleted successfully", {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          getDamnData();
        }
      },
      (err) => {
        console.log(err);
        // alert('an  error occured!!!!!!')
      }
    );
    getDamnData();
  };
  const fields = [
    {
      title: "Adminstrative",
      custom: true,
      component: (ite) => ite.admin_description,
    },
    {
      title: "Admin Code",
      custom: true,
      component: (item) => item.admin_code,
    },
    {
      title: "Economic Description",
      custom: true,
      component: (ite) => ite.economic_description,
    },
    {
      title: "Economic Code",
      custom: true,
      component: (item) => item.economic_code,
    },
    {
      title: "Fund Description",
      custom: true,
      component: (ite) => ite.fund_description,
    },
    { title: "Fund Code", custom: true, component: (ite) => ite.fund_code },
    {
      title: "Program Description",
      custom: true,
      component: (ite) => ite.program_description,
    },
    {
      title: "Program Code",
      custom: true,
      component: (ite) => ite.program_code,
    },
    {
      title: "Functional Description",
      custom: true,
      component: (ite) => ite.function_description,
    },
    {
      title: "Functional code",
      custom: true,
      component: (ite) => ite.function_code,
    },
    {
      title: "Geo Description",
      custom: true,
      component: (ite) => ite.geo_description,
    },
    { title: "Geo code", custom: true, component: (ite) => ite.geo_code },
    {
      title: "Budget Type",
      custom: true,
      component: (ite) => ite.type_of_budget,
    },
    // {
    //   title: `${parseInt(form.year) - 2} Proposed(₦)`,
    //   custom: true,
    //   component: (ite) => ite.prev_year1_budget_amount,
    // },
    // {
    //   title: `${parseInt(form.year) - 2} Aproved(₦)`,
    //   custom: true,
    //   component: (ite) => ite.prev_year1_approved_amount,
    // },
    // {
    //   title: `${parseInt(form.year) - 2}  Actual(₦)`,
    //   custom: true,
    //   component: (ite) => (
    //     <div className="float-end">
    //       {formatNumber(ite.prev_year2_actual_amount)}
    //     </div>
    //   ),
    // },
    // {
    //   title: `${parseInt(form.year) - 1} Proposed(₦)`,
    //   custom: true,
    //   component: (ite) => ite.prev_year2_budget_amount,
    // },
    {
      title: `Aproved(₦)`,
      custom: true,
      component: (ite) => (
        <div className="float-end">{formatNumber(ite.approve_amount)} </div>
      ),
    },
    // {
    //   title: `${parseInt(form.year) - 1}  Actual(₦)`,
    //   custom: true,
    //   component: (ite) => (
    //     <div className="float-end">
    //       {formatNumber(ite.prev_year1_actual_amount)}
    //     </div>
    //   ),
    // },
    // {
    //   title: "Proposed Amount(₦)",
    //   custom: true,
    //   component: (ite, idx) => (
    //     <div
    //       onDoubleClick={() => handleDoubleClick(idx)}
    //       style={{ float: "right" }}
    //     >
    //       {ite.status === "draft" ? (
    //         editingIndex === idx ? (
    //           <Input
    //             name="budget_amount"
    //             value={ite.budget_amount}
    //             onChange={({ target: { value } }) => handleChanges(value, idx)}
    //             onBlur={handleInputBlur}
    //             style={{ width: "120px", alignItems: "right" }}
    //           />
    //         ) : (
    //           <div className="float-end">{formatNumber(ite.budget_amount)}</div>
    //         )
    //       ) : (
    //         <div className="float-end">{formatNumber(ite.budget_amount)}</div>
    //       )}
    //     </div>
    //   ),
    // },

    // {
    //   title: "Action",
    //   custom: true,
    //   component: (ite) => (
    //     <div className="text-center">
    //       <button
    //         disabled={ite.status === "draft" ? false : true}
    //         className="btn btn-danger btn-sm"
    //         color="primary"
    //         onClick={() => handleDelete(ite.id)}
    //       >
    //         <BsXSquare />
    //       </button>
    //     </div>
    //   ),
    // },
  ];

  const [results5, setResults5] = useState([]);
  // let data = results&&results.map(item => item.name)
  useEffect(() => {
    getSector({ type: "ADMINISTRATIVE SEGMENT" }, "select", setResults5);
  }, []);

  const handleChanges = (value, index) => {
    const arr = [...results];
    arr[index].budget_amount = value;
    setResults(arr);
  };
  const { addToast } = useToasts();

  const handleSubmit = () => {
    const filteredResults = results?.filter((item) => item.status === "draft");
    _postApi(
      `/post-budget?query_type=UPDATE&status=final-proposal`,
      filteredResults,
      (res) => {
        if (res.success) {
          addToast("Submit successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        }
      },
      (err) => {
        console.log(err);
        // alert('an  error occured!!!!!!')
      }
    );
  };
  const [results4, setResult4] = useState([]);
  const [expired, setExpired] = useState([]);

  useEffect(() => {
    // getSector({ type: "mdas" }, "mdas", (d) => {
    //   setResult4(d);
    // });
    // expiredSetting(
    //   { description: "Proposed Budget End Date" },
    //   "select",
    //   (d) => {
    //     setExpired(d.results);
    //   },
    //   (e) => {
    //     console.log(e);
    //   }
    // );
  }, []);

  const totalAmount = results?.reduce(
    (total, item) => parseFloat(item.budget_amount) + total,
    0
  );

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  let _expired = expired[0]?.end_date;
  const submissionDeadline = moment(_expired);
  const daysLeft = submissionDeadline.diff(moment(), "days");
  const filteredResults = results?.filter((item) => item.status === "draft");
  const [print, setPrint] = useState(false);

  let data = [];

  results?.forEach((item) => {
    if (
      (item.fund_description &&
        item.function_description
          ?.toLowerCase()
          .indexOf(searchQuery?.toLowerCase()) === -1) ||
      (item.fund_code &&
        item.fund_code?.toLowerCase().indexOf(searchQuery?.toLowerCase()) ===
          -1) ||
      (item.mda_name &&
        item.mda_name?.toLowerCase().indexOf(searchQuery?.toLowerCase()) === -1)
    )
      return;
    else data.push(item);
  });

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  let newBudget = { type_of_budget: "All" };
  let isObjectExists = budget?.some(
    (item) => item.type_of_budget === newBudget.type_of_budget
  );

  // If the object doesn't exist, push it into the array
  if (!isObjectExists) {
    budget?.push(newBudget);
  }
  const tableRef = useRef(null);
  let aheadData1 =
    results5 &&
    results5?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  const repRef1 = useRef(null);
  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                my={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" gutterBottom color="white">
                  <center>
                    <h2 style={{ fontSize: "25px" }}>BUDGET BREAKDOWN</h2>
                  </center>
                </MDTypography>
              </MDBox>

              {print ? (
                <div>
                  <Button color="danger" onClick={() => setPrint(!print)}>
                    cancel
                  </Button>
                  <br />
                  <PDFViewer width="1000" height="600">
                    {" "}
                    {/* Adjust the width and height as needed */}
                    <MyPDF data={data} />
                  </PDFViewer>
                </div>
              ) : (
                <MDBox>
                  <center></center>
                  {/* {JSON.stringify(budget)} */}
                  <MDBox pt={3}>
                    <div className=" row mb-4 p-2">
                      <div className=" justify-content-end">
                        <div className="col-md-6">
                          {/* <button
                            className="btn btn-dark px-3 m-2"
                            onClick={() => navigate("/budget-form")}
                          >
                            Add New Item
                          </button> */}
                          {/* <Button
                            color="primary"
                            className="px-5 m-2"
                            // style={{ float: "right" }}
                            onClick={handleSubmit}
                            disabled={
                              filteredResults?.length === 0 ? true : false
                            }
                          >
                            Submit
                          </Button> */}
                          {/* <Button color="success" className="m-2">
                            <CSVLink
                              data={results && results ? results : []}
                              className="text-white"
                              filename={`${user?.mda} ${form.year} budget proposal  `}
                            >
                              <SiMicrosoftexcel /> Download to Excel
                            </CSVLink>
                          </Button> */}
                        </div>
                      </div>
                      {/* <div className="col-md-6 mb-3"> */}
                      {/* <CustomTypeahead
                          labelKey="name"
                          label="Budget type"
                          options={aheadData}
                          onChange={(selected) => {
                            setForm((p) => ({ ...p, type: selected[0]?.name }));
                          }}
                        /> */}
                      {/* </div> */}
                      <div className="col-md-4">
                        <CustomTypeahead
                          ref={repRef1}
                          labelKey={"description"}
                          label="Adminstrative Description"
                          options={aheadData1}
                          onChange={(s) => {
                            if (s.length) {
                              setForm((p) => ({
                                ...p,
                                admin_description: s[0].description,
                                mda_name: s[0].description,
                              }));
                            }
                          }}
                          defaultInputValue={form.admin_description}
                        />
                      </div>
                      <div className="col-md-4 mb-4 ">
                        <CustomTypeahead
                          labelKey="type_of_budget"
                          label="Budget type"
                          options={budget || []}
                          onChange={(selected) => {
                            setForm((p) => ({
                              ...p,
                              status: selected[0]?.type_of_budget,
                            }));
                          }}
                        />
                      </div>
                      <div className="col-md-2">
                        <label>Year</label>
                        <Input
                          type="select"
                          name="year"
                          value={form.year}
                          onChange={handleChange}
                        >
                          <option>--select--</option>
                          {years?.map((item) => (
                            <option key={item}>{item}</option>
                          ))}
                        </Input>
                      </div>

                      <div className="col-md-2 mt-3">
                        <DownloadTableExcel
                          filename="BUDGET BREAKDOWN"
                          sheet="BUDGET BREAKDOWN"
                          currentTableRef={tableRef.current}
                        >
                          <Button color="success" className="m-2">
                            {" "}
                            <SiMicrosoftexcel /> Download to Excel
                          </Button>
                        </DownloadTableExcel>
                      </div>

                      {/* <Label>Select year</Label> */}
                      {/* <div className="col-md-4 ">
                        <Input
                          type="select"
                          name="year"
                          value={form.year}
                          onChange={handleChange}
                        >
                          <option>--select--</option>
                          {years?.map((item) => (
                            <option key={item}>{item}</option>
                          ))}
                        </Input>
                      </div> */}
                      {/*  <div className="col-md-4">
                        <SearchBar
                          placeholder="Search By fund code or description"
                          filterText={searchQuery}
                          onFilterTextChange={handleSearchChange}
                        />
                      </div> */}
                      {/* {JSON.stringify(parseInt(this_year) + 1)} */}
                      {/* <div className="col-md-4">
                        <button
                          className="btn btn-dark px-3"
                          onClick={() => navigate("/budget-form")}
                        >
                          Add New Item
                        </button>
                        <Button
                          color="primary"
                          className="px-5"
                          style={{ float: "right" }}
                          onClick={handleSubmit}
                          disabled={
                            filteredResults?.length === 0 ? true : false
                          }
                        >
                          Submit
                        </Button>
                      </div> */}

                      <div>
                        <span style={{ fontWeight: "bold" }}>
                          Total Budget Size : ₦{totalAmount?.toLocaleString()}
                        </span>
                        {/* <Button color="success" className="m-2">
                          <CSVLink
                            data={results && results ? results : []}
                            className="text-white"
                            filename={`${user?.mda} ${form.year} budget proposal  `}
                          >
                            <SiMicrosoftexcel /> Download to Excel
                          </CSVLink>
                        </Button> */}
                        {/* <Button color="success" onClick={printItemList}>
                          <BsPrinter /> Print
                        </Button> */}
                      </div>
                      <Scrollbar>
                        {/* {JSON.stringify(results)} */}

                        <CustomTable
                          fields={fields}
                          data={data}
                          type="proposals"
                          tableRef={tableRef}
                        />
                      </Scrollbar>
                      {/* <center>
                        <Button
                          color="success"
                          className="pr-5 pl-5 btn btn-success"
                          onClick={handleSubmit}
                          disabled={
                            filteredResults?.length === 0 ? true : false
                          }
                        >
                          Submit
                        </Button>
                      </center> */}
                    </div>
                  </MDBox>
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BudgetBreakdown;
