/* eslint-disable react/prop-types */
import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import moment from "moment";

const RemarksList = ({ remarks }) => {
  if (!remarks || remarks?.length === 0) {
    return <p>No remarks available.</p>;
  }

  // Sort remarks by created_at (latest first)
  const sortedRemarks = [...remarks].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  // Get the latest remark separately
  const latestRemark = sortedRemarks[0];
  const otherRemarks = sortedRemarks.slice(1);

  return (
    <ListGroup>
      {/* Latest Remark with Highlight */}
      <ListGroupItem className="bg-success text-white">
        <strong style={{ fontWeight: "bold", borderRadius: "10px" }}>
          Latest Remark: <br />
        </strong>{" "}
        {latestRemark.remark} <br />
        <strong>Time:</strong>{" "}
        <small>{moment(latestRemark.created_at).fromNow()}</small>
      </ListGroupItem>

      {/* Other Remarks */}
      {otherRemarks?.map((remark) => (
        <ListGroupItem key={remark.id}>
          {remark.remark} <br />
          <small>
            <strong>Time:</strong> {moment(remark.created_at).fromNow()}
          </small>
        </ListGroupItem>
      ))}
    </ListGroup>
  );
};

export default RemarksList;
