import React, { useEffect, useRef, useState, useCallback } from "react";
// @mui material components
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Grid } from "@mui/material";
import { getSector } from "helper";
import CustomTypeahead from "components/CustomTypeahead";
// import { FaPlus } from "react-icons/fa";
import { _postApi } from "redux/action/api";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatNumber } from "redux/action/api";
import moment from "moment";
import { expiredSetting } from "examples/admin/helper";
import { useToasts } from "react-toast-notifications";
import BackButton from "./Back";
import { _fetchApi } from "redux/action/api";
import { Button, Col, Table } from "reactstrap";
import ProposalTable from "./ProposalTable";
import { FaEdit, FaPlus } from "react-icons/fa";
import { MdDrafts } from "react-icons/md";
import { getNextYear } from "./getBudgetYear";
import { FaTrash } from "react-icons/fa6";

function BudgetProposal() {
  const mda = useSelector((state) => state.auth.user);
  let mda_name = mda.mda;
  let mda_code = mda.mda_code;

  const [data, setData] = useState([]);
  const [form, setForm] = useState({
    budget_year: "",
    economic_code: "",
    economic_description: "",
    program_code: "",
    program_description: "",
    function_code: "",
    function_description: "",
    fund_code: "",
    fund_description: "",
    geo_code: "",
    geo_description: "",
    budget_amount: "",
    actual_amount: "",
    type_of_budget: "",
    start_date: "",
    end_date: "",
    segment: "",
    mda_name: mda_name,
    mda_code: mda_code,
    project_description: "",
    type_of_funding: "normal",
    admin_code: "",
    admin_description: "",
    economic_sub_code: "",
    economic_sub_description: "",
    function_sub_code: "",
    program_sub_code: "",
    function_sub_code: "",
    fund_sub_code: "",
    fund_code2: "",
    fund_code3: "",
  });

  const [adminData, setAdminData] = useState([]);
  const [economicData, setEconomicData] = useState([]);
  const [fundData, setFundData] = useState([]);
  const [geoData, setGeoData] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [functionalData, setFunctionalData] = useState([]);

  const [results6, setResults6] = useState([]);
  const maxLength = 240;
  const _type =
    form.type_of_budget === "Capital"
      ? "23%"
      : form.type_of_budget === "Capital Receipt"
      ? "14%"
      : form.type_of_budget === "Personnel"
      ? "21%"
      : form.type_of_budget === "OverHead"
      ? "22%"
      : form.type_of_budget === "Revenue"
      ? "1%"
      : null;
  const newGetMdaData = useCallback(() => {
    _postApi(
      `/select-mda-mapping?query_type=not_mapped_econ`,
      { type: _type },
      (data) => {
        setEconomicData(data.results);
      },
      (err) => console.log(err)
    );
  }, [_type]);
  const [lineEco, setinEco] = useState([]);
  const economicLineItem = useCallback(() => {
    _postApi(
      `/select-mda-mapping?query_type=by_type`,
      { type: "Economic Segment", sub_head: form.economic_sub_code },
      (data) => {
        setinEco(data.results);
      },
      (err) => console.log(err)
    );
  }, [form.economic_sub_code]);

  const getProgra = useCallback(() => {
    _postApi(
      `/select-mda-mapping?query_type=progs`,
      {},
      (data) => {
        setProgramData(data.results);
      },
      (err) => console.log(err)
    );
  }, []);

  const [lineProg, setLinProg] = useState([]);
  const progcLineItem = useCallback(() => {
    _postApi(
      `/select-mda-mapping?query_type=by_type`,
      { type: "Programme Segment", sub_head: form.program_sub_code },
      (data) => {
        setLinProg(data.results);
      },
      (err) => console.log(err)
    );
  }, [form.program_sub_code]);

  useEffect(() => {
    newGetMdaData();
    economicLineItem();
    getProgra();
    progcLineItem();
  }, [newGetMdaData, economicLineItem, progcLineItem]);

  const getMdaData = useCallback(
    (type, callback) => {
      _postApi(
        `/select-mda-mapping?query_type=select_mda`,
        { mda_name: mda_name, type },
        (data) => {
          callback(data.results);
        },
        (err) => console.log(err)
      );
    },
    [mda]
  );

  const getMdaMapped = useCallback(() => {
    _postApi(
      `/account?query_type=mda_mapping`,
      { sub_head: mda_code },
      (data) => {
        setAdminData(data.results);
        console.log(data, "DDDDDD");
      },
      (err) => console.log(err)
    );
  }, [mda_code]);
  const [expired, setExpired] = useState([]);

  // const getHeads = () => {
  //   _postApi(
  //     `/budgetCeiling?query_type=select`,
  //     {},
  //     (data) => {
  //       setData(data.results);
  //     },
  //     (err) => console.log(err)
  //   );
  // };

  // useEffect(() => {
  //   getSector({ sub_head: dd }, "select_subheds", (d) => {
  //     setResults1(d);
  //   });
  // }, [dd]);

  // useEffect(() => {
  //   getSector(
  //     { type: form.segment, sub_head: [sub_head_list] },
  //     "select",
  //     setResults
  //   );
  //   getHeads();
  // }, []);

  useEffect(() => {
    getSector({ type: "ECONOMIC SEGMENT" }, "select", setResults6);
  }, []);
  const totalFunds =
    parseFloat(form.fund_amt1 || 0) +
    parseFloat(form.fund_amt2 || 0) +
    parseFloat(form.fund_amt3 || 0);
  const isBudgetExceeded =
    parseFloat(totalFunds) > parseFloat(form.budget_amount);
  const isBudgetLesser =
    parseFloat(totalFunds) < parseFloat(form.budget_amount);

  const handleChange = ({ target: { name, value } }) => {
    const intValue = parseFloat(value);

    // Update form state with the changed value
    setForm((prevForm) => ({ ...prevForm, [name]: intValue }));

    // Calculate the total of fund1, fund2, fund3, and fund4

    // Disable the funds inputs if the total funds exceed the budget_amount

    if (isBudgetExceeded) {
      alert("Total funds cannot exceed or be less than budget amount!");
      setForm((prevForm) => ({ ...prevForm, [name]: "" }));
    }
    // Set the funds inputs to disabled if the budget is exceeded
    // document.querySelectorAll(".fundInput").forEach((input) => {
    //   input.disabled = isBudgetExceeded;
    // });
  };

  const _handleChange = ({ target: { name, value } }) => {
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
    if (name === "type_of_budget") {
      handleReset();
      setForm((prevForm) => ({ ...prevForm, [name]: value }));
    }
  };

  useEffect(() => {
    getNextYear((data) => setForm((p) => ({ ...p, budget_year: data })));
  }, []);
  // const handleChanges = (name, value, index) => {
  //   const arr = [...results1];
  //   arr[index][name] = value;
  //   arr[index] = { ...arr[index], ...form };
  //   adminData(arr);
  // };

  // const handleDelete = (index) => {
  //   const arr = [...results1];
  //   arr.splice(index, 1);
  //   adminData(arr);
  // };

  const repRef = useRef();
  const repRef1 = useRef();
  const repRef1a = useRef();
  const repRef2 = useRef();
  const repRef2a = useRef();
  const repRef2b = useRef();
  const repRef2c = useRef();
  const repRef3 = useRef();
  const repRef4 = useRef();
  const repRef5 = useRef();

  const handleFinalProposal = () => {
    submitForm("final-proposal");
  };

  const handleSaveAsDraft = () => {
    submitForm("draft");
  };

  const navigate = useNavigate();
  const res = { ...form, mda_name: mda };
  const { addToast } = useToasts();

  const submitForm = (status) => {
    const apiEndpoint = `/post-budget?query_type=INSERT&status=${status}`;
    _postApi(
      apiEndpoint,
      data,
      (res) => {
        if (res.success) {
          addToast("Submitted Successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          navigate(-1);
        }
      },
      (err) => {
        console.log(err);
        alert("An error occurred!");
      }
    );
  };

  const [results4, setResults4] = useState([]);
  const [eco, setEco] = useState([]);
  useEffect(() => {
    getMdaMapped();
    getMdaData("GEO SEGMENT", setGeoData);
    getMdaData("ECONOMIC SEGMENT", setEco);
    // getMdaData("PROGRAMME SEGMENT", setProgramData);
    getMdaData("FUNCTIONAL SEGMENT", setFunctionalData);
    getSector({ type: "FUND SEGMENT" }, "select", setResults4);
    getMdaData("FUND SEGMENT", setFundData);
    expiredSetting(
      { description: "Proposed Budget End Date" },
      "select",
      (d) => {
        setExpired(d.results);
      },
      (e) => {
        console.log(e);
      }
    );
  }, [getMdaData, getMdaMapped]);

  const user = useSelector((e) => e.auth.user);
  let aheadData =
    adminData &&
    adminData?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  let aheadData2 =
    economicData &&
    economicData?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  let aheadData3 =
    geoData &&
    geoData?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData4 =
    programData &&
    programData?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  let aheadDataEco =
    lineEco &&
    lineEco?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  const handleReset = () => {
    repRef?.current?.clear();
    repRef1a?.current?.clear();
    repRef1?.current?.clear();
    repRef2?.current?.clear();
    repRef3?.current?.clear();
    repRef4?.current?.clear();
    repRef5?.current?.clear();
    repRef2a?.current?.clear();
    repRef2b?.current?.clear();
    repRef2c?.current?.clear();
    repRef3?.current?.clear();
    repRef4?.current?.clear();
    // }
    setForm((p) => ({
      ...p,
      fund_amt1: "",
      fund_amt2: "",
      fund_amt3: "",
      project_description: "",
      budget_year: 2025,
      admin_code: "",
      admin_description: "",
      economic_code: "",
      economic_description: "",
      program_code: "",
      program_description: "",
      function_code: "",
      function_description: "",
      fund_code: "",
      fund_description: "",
      geo_code: "",
      geo_description: "",
      budget_amount: "",
      actual_amount: "",
      start_date: "",
      end_date: "",
      mda_name: mda_name,
      mda_code: mda_code,
      project_description: "",
    }));
  };
  const handleDelete = (index) => {
    let item = data.filter((i, idx) => index !== idx);
    // alert(JSON.stringify(item))
    setData(item);
    console.log(index);
  };
  const handleAdd = () => {
    if (
      form.budget_amount === 0 ||
      form.budget_year === "" ||
      form.admin_description === ""
    ) {
      alert("Please fill the required fields");
    } else if (
      (form.fund_code === form.fund_code2 ||
        form.fund_code === form.fund_code3 ||
        form.fund_code2 === form.fund_code3) &&
      form.type_of_budget === "Capital"
    ) {
      alert("Fund codes must be unique. Please use different fund codes.");
    } else if (
      (isBudgetExceeded || isBudgetLesser) &&
      form.type_of_budget === "Capital"
    ) {
      alert("Total funds cannot exceed or be less than budget amount!");
    } else if (
      (form.type_of_budget === "Capital" ||
        form.type_of_budget === "Capital Receipt") &&
      form.project_description === ""
    ) {
      alert("Project description cannot be empty");
    } else {
      setData((p) => [...p, form]);

      // repRef.current.clear();

      // if () {
      handleReset();
      // }z
    }
  };

  const totalAmount = data?.reduce(
    (total, item) => parseFloat(item.budget_amount) + total,
    0
  );

  let _expired = expired[0]?.end_date;
  const submissionDeadline = moment(_expired);

  const daysLeft = submissionDeadline.diff(moment(), "days");
  const [ceiling, setCeiling] = useState([]);
  const [buget, setBuget] = useState([]);
  useEffect(() => {
    const encodedMda = encodeURIComponent(user?.mda);
    const encodedQueryType = encodeURIComponent("ceiling");

    _fetchApi(
      `/get-total-ceiling?mda_name=${encodedMda}&query_type=${encodedQueryType}`,
      (res) => {
        // alert(JSON.stringify(res.results));
        setCeiling(res.results);
      },
      (err) => {
        console.error(err);
      }
    );
    _fetchApi(
      `/get-total-ceiling?mda_name=${encodedMda}&query_type=budget`,
      (res) => {
        const budgetArray = res.results;

        const totalBudget = budgetArray.reduce((sum, item) => {
          const budgetAmount = parseInt(item.budget_amount, 10); // Convert string to integer
          return sum + budgetAmount;
        }, 0);

        setBuget(totalBudget);
      },
      (err) => {
        console.error(err);
      }
    );
  }, []);

  const totalCeiling = parseInt(ceiling[0]?.total_amt) - parseInt(buget);
  const [isFund, setIsFund] = useState(false);
  const styles = { fontSize: "12px" };
  const fields = [
    {
      title: "Project/ Receipt Description",
      custom: true,
      component: (item) => item.project_description,
      rowSpan: true,
    },
    {
      title: "Administrative",
      custom: true,
      component: (item) => item.admin_description,
      rowSpan: true,
      width: 100,
    },
    {
      title: "Admin Code",
      custom: true,
      component: (item) => item.admin_code,
      rowSpan: true,
    },
    {
      title: "Economic Description",
      custom: true,
      component: (item) => item.economic_description,
      rowSpan: true,
    },
    {
      title: "Economic Code",
      custom: true,
      component: (item) => item.economic_code,
      rowSpan: true,
    },
    {
      title: "Program Description",
      custom: true,
      component: (item) => item.program_description,
      rowSpan: true,
    },
    {
      title: "Program Code",
      custom: true,
      component: (item) => item.program_code,
      rowSpan: true,
    },
    {
      title: "Functional Code",
      custom: true,
      component: (item) => item.function_code,
      rowSpan: true,
    },
    {
      title: "Geo Description",
      custom: true,
      component: (item) => item.geo_description,
      rowSpan: true,
    },
    {
      title: "Geo Code",
      custom: true,
      component: (item) => item.geo_code,
      rowSpan: true,
    },
    {
      title: "Fund Description",
      custom: true,
      component: (item) => item.fund_description,
    },
    {
      title: "Fund Code",
      custom: true,
      component: (item) => <div>{item.fund_code ? item.fund_code : ""}</div>,
    },
    {
      title: "Fund Amount",
      custom: true,
      component: (item) => (
        <div style={{ textAlign: "right" }}>{formatNumber(item.fund_amt1)}</div>
      ),
    },
    {
      title: "Proposed Amount",
      custom: true,
      component: (item) => (
        <div style={{ textAlign: "right" }}>
          {formatNumber(item.budget_amount)}
        </div>
      ),
      rowSpan: true,
    },
    {
      title: "Delete",
      custom: true,
      component: (item, index) => (
        <div style={{ textAlign: "right" }}>
          {/* <Button color="primary" onClick={()=>{setForm((p) => ({ ...p, ...item }))}}><FaEdit /></Button> */}
          <Button size="sm" color="danger" onClick={() => handleDelete(index)}>
            <FaTrash />
          </Button>
        </div>
      ),
      rowSpan: true,
    },
  ];
  const [byFund, setByFund] = useState([]);
  const [byFunc, setByFunc] = useState([]);

  const getFundMap = (economic_code) => {
    _fetchApi(
      `/get-mapping?code=${economic_code}&type=eco_by_fund`,
      (res) => {
        setByFund(res.results);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const getFuncMap = (economic_code) => {
    _fetchApi(
      `/get-mapping?code=${economic_code}&type=eco_by_func`,
      (res) => {
        setByFunc(res.results);
        // alert(JSON.stringify(res.results));
      },
      (err) => {
        console.log(err);
      }
    );
  };

  let aheadData5 =
    byFunc &&
    byFunc?.map((i) => ({
      head: `${i.mapped_code}-${i.mapped_description}`,
      description: i.mapped_description,
      code: i.mapped_code,
    }));

  let aheadData6 =
    byFund &&
    byFund?.map((i) => ({
      head: `${i.mapped_code}-${i.mapped_description}`,
      description: i.mapped_description,
      code: i.mapped_code,
    }));

  let aheadDataProg =
    lineProg &&
    lineProg?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  const budgetTypeMapping = {
    Capital: "23",
    "Capital Receipt": "14",
    Personnel: "21",
    OverHead: "22",
    Revenue: "1",
  };

  const isBudgetTypeAvailable = (type) => {
    const startDigit = budgetTypeMapping[type];
    return eco?.some((item) => item.head.startsWith(startDigit));
  };

  return (
    <>
      <DashboardLayout style={{ marginTop: 0 }}>
        <MDBox pt={4} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="success"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <center>
                    <MDTypography variant="h2" color="white">
                      <center>{user?.mda}</center>
                    </MDTypography>
                  </center>
                </MDBox>
                <MDBox>
                  <div className="d-flex">
                    <BackButton className="mt-2 m-2 mr-5" />
                    <div style={{ marginRight: "300px" }}></div>
                    <MDTypography variant="h5" color="dark" mt={2} mr={5}>
                      <center>Budget Proposal Page</center>
                    </MDTypography>
                  </div>

                  <br />
                  <b className="m-5">
                    Total Ceiling :₦{formatNumber(ceiling[0]?.total_amt || 0)}
                    {/* {JSON.stringify({adminData})} */}
                  </b>
                  <b className="m-5">
                    Total Budget :₦{formatNumber(buget || 0)}
                  </b>
                  <b className="m-5">
                    Actual Amount :₦{formatNumber(totalCeiling || 0)}
                  </b>
                  <MDBox pt={3}>
                    {/* <center>
                      <div
                        className={
                          daysLeft < 20 ? "text-danger" : "text-success"
                        }
                      >
                        {daysLeft} {daysLeft === 1 ? "day" : "days"} left to
                        submit your budget proposal, Submission will close on{" "}
                        {submissionDeadline.format("Do MMMM, YYYY")}
                      </div>
                    </center> */}
                    <div className=" row mb-4 p-3">
                      <div className="col-md-4">
                        <lable style={styles}>Budget Year</lable>
                        <input
                          className="form-control"
                          type="number"
                          maxLength="4"
                          name="budget_year"
                          value={form.budget_year}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>Type of Funding</label>
                        <select
                          className="form-control"
                          value={form.type_of_funding}
                          onChange={({ target: { value } }) =>
                            setForm((prev) => ({
                              ...prev,
                              type_of_funding: value,
                            }))
                          }
                        >
                          <option value={"normal"}>Normal</option>
                          <option value={"counterpart"}>
                            Counterpart Funding
                          </option>
                          <option value={"Countengency Recurrent"}>
                            Countengency Recurrent
                          </option>
                          <option value={"Countengency Capital"}>
                            Countengency Capital
                          </option>
                        </select>
                      </div>
                      <div className="col-md-4">
                        <lable style={styles}>Budget type</lable>
                        <select
                          className="form-control"
                          value={form.type_of_budget}
                          name="type_of_budget"
                          onChange={_handleChange}
                        >
                          <option>...Select...</option>
                          {/* <option value="Capital">Capital</option> */}
                          {Object.keys(budgetTypeMapping).map(
                            (type) =>
                              isBudgetTypeAvailable(type) && (
                                <option key={type} value={type}>
                                  {type}
                                </option>
                              )
                          )}
                        </select>
                      </div>

                      {/* {JSON.stringify(lineEco)} */}

                      {/* <div className="col-md-4">
                        <lable style={styles}>Capital Receipt </lable>
                        <input
                          className="form-control"
                          name="capital_receipt"
                          value={form.capital_receipt}
                          onChange={_handleChange}
                        />
                      </div> */}
                      <div className="col-md-4">
                        <CustomTypeahead
                          ref={repRef}
                          labelKey="head"
                          label="Adminstrative Segment"
                          options={aheadData || []}
                          onChange={(s) => {
                            if (s.length) {
                              setForm((p) => ({
                                ...p,
                                admin_code: s[0].code || "",
                                admin_description: s[0].description || "",
                              }));
                            }
                          }}
                          //            defaultSelected={[
                          //   {
                          //     head: `${form.admin_code}-${form.admin_description}`,
                          //     code: form.admin_code,
                          //     description: form.admin_description,
                          //   },
                          // ]}
                        />
                      </div>
                      <div className="col-md-4">
                        <CustomTypeahead
                          ref={repRef1a}
                          labelKey="head"
                          label="Economic Sub Codes"
                          options={aheadData2||[]}
                          onChange={(s) => {
                            if (s.length) {
                              setForm((p) => ({
                                ...p,
                                economic_sub_code: s[0].code || "",
                                economic_sub_description:
                                  s[0].description || "",
                                economic_code: "", // Clear the economic_code
                                economic_description: "", // Clear the economic_description
                              }));
                              // getFundMap(s[0].code);
                              // getFuncMap(s[0].code);
                            } else {
                              setForm((p) => ({
                                ...p,
                                economic_sub_code: "",
                                economic_sub_description: "",
                                economic_code: "", // Ensure these are cleared if no selection is made
                                economic_description: "",
                              }));
                            }
                          }}
                        />
                      </div>
                      {form.economic_sub_code === "" ||
                      form.economic_sub_description === "" ? null : (
                        <div className="col-md-4">
                          <CustomTypeahead
                            ref={repRef1}
                            labelKey="head"
                            label="Economic line Code"
                            options={aheadDataEco ||[]}
                            onChange={(s) => {
                              if (s.length) {
                                setForm((p) => ({
                                  ...p,
                                  economic_code: s[0].code || "",
                                  economic_description: s[0].description || "",
                                  function_code: "",
                                  function_description: "",
                                  fund_code: "",
                                  fund_description: "",
                                }));
                                getFundMap(s[0].code);
                                getFuncMap(s[0].code);
                              } else {
                                setForm((p) => ({
                                  ...p,
                                  economic_code: "",
                                  economic_description: "",
                                  function_code: "",
                                  function_description: "",
                                  fund_code: "",
                                  fund_description: "",
                                }));
                              }
                            }}
                          />
                        </div>
                      )}

                      {/* <div
                        style={
                          form.type_of_budget === "Capital"
                            ? {
                                display: "none",
                              }
                            : { display: "block" }
                        }
                        className="col-md-4"
                      >
                        <CustomTypeahead
                          ref={repRef2}
                          labelKey="head"
                          label="Fund Code"
                          options={aheadData6 ||[]}
                          onChange={(s) => {
                            if (s.length) {
                              setForm((p) => ({
                                ...p,
                                fund_sub_code: s[0].code || "",
                                fund_sub_description: s[0].description || "",
                                fund_code: "",
                                fund_description: "",
                              }));
                            } else {
                              setForm((p) => ({
                                ...p,
                                fund_sub_code: "",
                                fund_sub_description: "",
                                fund_code: "",
                                fund_description: "",
                              }));
                            }
                          }}
                        />
                      </div> */}

                      <div
                        style={
                          form.type_of_budget === "Capital"
                            ? {
                                display: "none",
                              }
                            : { display: "block" }
                        }
                        className="col-md-4"
                      >
                        <CustomTypeahead
                          ref={repRef2}
                          labelKey="head"
                          label="Fund Line Code"
                          options={aheadData6}
                          onChange={(s) => {
                            if (s.length) {
                              setForm((p) => ({
                                ...p,
                                fund_code: s[0].code || "",
                                fund_description: s[0].description || "",
                              }));
                            }
                          }}
                        />
                      </div>

                      {/* {form.project_description === "" && ( */}
                      {form.type_of_budget === "Capital Receipt" ||
                      form.type_of_budget === "Revenue" ? null : (
                        <>
                          <div className="col-md-4">
                            <CustomTypeahead
                              ref={repRef3}
                              labelKey="head"
                              label="Geo Segment"
                              options={aheadData3 ||[]}
                              onChange={(s) => {
                                if (s.length) {
                                  setForm((p) => ({
                                    ...p,
                                    geo_code: s[0].code || "",
                                    geo_description: s[0].description || "",
                                  }));
                                }
                              }}
                              //              defaultSelected={[
                              //   {
                              //     head: `${form.geo_code}-${form.geo_description}`,
                              //     code: form.geo_code,
                              //     description: form.geo_description,
                              //   },
                              // ]}
                            />
                          </div>

                          <div className="col-md-4">
                            <CustomTypeahead
                              ref={repRef4}
                              labelKey="head"
                              label="Program Sub Code"
                              options={aheadData4 || []}
                              onChange={(s) => {
                                if (s.length) {
                                  setForm((p) => ({
                                    ...p,
                                    program_sub_code: s[0].code || "",
                                    program_sub_description:
                                      s[0].description || "",
                                    program_code: "",
                                    program_description: "",
                                  }));
                                } else {
                                  setForm((p) => ({
                                    ...p,
                                    program_sub_code: "",
                                    program_sub_description: "",
                                    program_code: "",
                                    program_description: "",
                                  }));
                                }
                              }}
                            />
                          </div>
                          {form.program_sub_code === "" ? null : (
                            <div className="col-md-4">
                              <CustomTypeahead
                                ref={repRef4}
                                labelKey="head"
                                label="Program Line Code"
                                options={aheadDataProg ||[]}
                                onChange={(s) => {
                                  if (s.length) {
                                    setForm((p) => ({
                                      ...p,
                                      program_code: s[0].code || "",
                                      program_description:
                                        s[0].description || "",
                                    }));
                                  }
                                }}
                              />
                            </div>
                          )}

                          <div className="col-md-4">
                            <CustomTypeahead
                              ref={repRef5}
                              labelKey="head"
                              label="Functional Code"
                              options={aheadData5}
                              onChange={(s) => {
                                if (s.length) {
                                  setForm((p) => ({
                                    ...p,
                                    function_code: s[0].code || "",
                                    function_description:
                                      s[0].description || "",
                                  }));
                                }
                              }}
                            />
                          </div>
                        </>
                      )}
                      <div className="col-md-4">
                        <small style={styles}>
                          Proposed Amount{" "}
                          <b>({formatNumber(form.budget_amount)})</b>
                        </small>
                        <input
                          className="form-control"
                          type="number"
                          maxLength="4"
                          name="budget_amount"
                          value={form.budget_amount}
                          onChange={handleChange}
                        />
                      </div>

                      {/* )} */}
                      {form.type_of_budget === "Capital" ? (
                        <>
                          <div className="col-md-4">
                            <CustomTypeahead
                              ref={repRef2a}
                              labelKey="head"
                              label="Fund 1 Code & Description"
                              options={aheadData6 ||[]}
                              onChange={(s) => {
                                if (s.length) {
                                  setForm((p) => ({
                                    ...p,
                                    fund_code: s[0].code || "",
                                    fund_description: s[0].description || "",
                                  }));
                                }
                              }}
                              //             defaultSelected={[
                              //   {
                              //     head: `${form.fund_code}-${form.fund_description}`,
                              //     code: form.fund_code,
                              //     description: form.fund_description,
                              //   },
                              // ]}
                            />
                          </div>
                          <Col md={4}>
                            <label>
                              Fund 1 Amount{" "}
                              <b>({formatNumber(form.fund_amt1)})</b>
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              // maxLength="4"
                              name="fund_amt1"
                              value={form.fund_amt1}
                              onChange={handleChange}
                            />
                          </Col>
                          <div className="col-md-4">
                            <CustomTypeahead
                              ref={repRef2b}
                              labelKey="head"
                              label="Fund 2 Code & Description"
                              options={aheadData6 ||[]}
                              onChange={(s) => {
                                if (s.length) {
                                  setForm((p) => ({
                                    ...p,
                                    fund_code2: s[0].code || "",
                                    fund_desc2: s[0].description || "",
                                  }));
                                }
                              }}
                              //             defaultSelected={[
                              //   {
                              //     head: `${form.fund_code2}-${form.fund_desc2}`,
                              //     code: form.fund_code2,
                              //     description: form.fund_desc2,
                              //   },
                              // ]}
                            />
                          </div>
                          <Col md={4}>
                            {" "}
                            <label>
                              Fund2 Amount{" "}
                              <b>({formatNumber(form.fund_amt2)})</b>
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              // maxLength="4"
                              name="fund_amt2"
                              value={form.fund_amt2}
                              onChange={handleChange}
                            />
                          </Col>{" "}
                          <div className="col-md-4">
                            <CustomTypeahead
                              ref={repRef2c}
                              labelKey="head"
                              label="Fund 3 Code  & Description"
                              options={aheadData6 ||[]}
                              onChange={(s) => {
                                if (s.length) {
                                  setForm((p) => ({
                                    ...p,
                                    fund_code3: s[0].code || "",
                                    fund_desc3: s[0].description || "",
                                  }));
                                }
                              }}
                              // defaultSelected={[
                              //   {
                              //     head: `${form.fund_code3}-${form.fund_desc3}`,
                              //     code: form.fund_code3,
                              //     description: form.fund_desc3,
                              //   },
                              // ]}
                            />
                          </div>
                          <Col md={4}>
                            {" "}
                            <label>
                              Fund3 Amount{" "}
                              <b>({formatNumber(form.fund_amt3)})</b>
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              // maxLength="4"
                              name="fund_amt3"
                              value={form.fund_amt3}
                              onChange={handleChange}
                            />
                          </Col>
                        </>
                      ) : null}

                      {form.type_of_budget === "Capital" ||
                      form.type_of_budget === "Capital Receipt" ? (
                        <div className="col-md-12">
                          <lable style={styles}>
                            {form.type_of_budget === "Capital Receipt"
                              ? "Reciept Description"
                              : "Project Description"}
                          </lable>
                          <textarea
                            className="form-control"
                            name="project_description"
                            value={form.project_description}
                            onChange={_handleChange}
                            maxLength={maxLength}
                            cols="10"
                            rows="2"
                          />
                        </div>
                      ) : null}
                    </div>
                    <center className="mb-3">
                      <button
                        className="btn btn-primary px-5"
                        onClick={handleAdd}
                      >
                        Add
                      </button>
                    </center>
                    <div className="p-3">
                      <span style={{ fontWeight: "bold", float: "right" }}>
                        TOTAL : {formatNumber(totalAmount || 0)}
                      </span>
                    </div>
                    <br />
                    {/* <Scrollbar> */}
                    {/* {JSON.stringify(results[0])} */}

                    {/* <CustomTable fields={fields} data={data} /> */}
                    <ProposalTable data={data} fields={fields} />
                    {data.length ? (
                      <center>
                        <button
                          className="btn btn-success pr-5 pl-5 m-3"
                          onClick={handleFinalProposal}
                        >
                          <FaPlus /> Final Proposal
                        </button>
                        <button
                          className="btn btn-warning pr-5 pl-5 px-2 m-3"
                          onClick={handleSaveAsDraft}
                        >
                          <MdDrafts /> Save As Draft
                        </button>
                      </center>
                    ) : (
                      ""
                    )}
                    {/* <Table bordered striped responsive className="proposal-table">
                      <thead>
                        <tr>
                          <th>Economic Description</th>
                          <th>Economic Code</th>
                          <th>Program Description</th>
                          <th>Program Code</th>
                          <th>Functional Code</th>
                          <th>Geo Description</th>
                          <th>Geo Code</th>
                          <th>Fund Description</th>
                          <th>Fund Code</th>
                          <th>Fund Amount</th>
                          <th>Proposed Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td rowSpan={3}>{item.economic_description}</td>
                              <td rowSpan={3}>{item.economic_code}</td>
                              <td rowSpan={3}>{item.program_desc}</td>
                              <td rowSpan={3}>{item.program_code}</td>
                              <td rowSpan={3}>{item.functional_code}</td>
                              <td rowSpan={3}>{item.geo_desc}</td>
                              <td rowSpan={3}>{item.geo_code}</td>

                              <td>{item.fund_description}</td>
                              <td>{item.fund_code}</td>
                              <td
                                style={{ textAlign: "right" }}
                                className="text-right"
                              >
                                {formatNumber(item.fund_amt1)}
                              </td>
                              <td
                                rowSpan={3}
                                style={{ textAlign: "right" }}
                                className="text-right"
                              >
                                {formatNumber(item.budget_amount)}
                              </td>
                            </tr>
                            <tr>
                              <td>{item.fund_desc2}</td>
                              <td>{item.fund_code2}</td>
                              <td
                                style={{ textAlign: "right" }}
                                className="text-right"
                              >
                                {formatNumber(item.fund_amt2)}
                              </td>
                            </tr>
                            <tr>
                              <td>{item.fund_desc3}</td>
                              <td>{item.fund_code3}</td>
                              <td
                                style={{ textAlign: "right" }}
                                className="text-right"
                              >
                                {formatNumber(item.fund_amt3)}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </Table> */}

                    {/* </Scrollbar> */}
                    {/* {data.length ? (
                      <center>
                        <button
                          className="btn btn-success pr-5 pl-5 m-3"
                          onClick={handleFinalProposal}
                        >
                          <FaPlus /> Final Proposal
                        </button>
                        <button
                          className="btn btn-warning pr-5 pl-5 px-2 m-3"
                          onClick={handleSaveAsDraft}
                        >
                          <MdDrafts /> Save As Draft
                        </button>
                      </center>
                    ) : (
                      ""
                    )} */}
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default BudgetProposal;