import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { useCallback, useEffect, useState } from "react";
import { getSector } from "helper";
import { unflatten } from "redux/action/api";
import StructureTree from "components/Tree/StrutureTree";
import Scrollbar from "components/Tree/Scrollbar";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";
import { _postApi } from "redux/action/api";
import { _fetchApi } from "redux/action/api";
import {
  Button,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Col,
  ListGroup,
  ListGroupItem,
  ButtonGroup,
} from "reactstrap";
import { useToasts } from "react-toast-notifications";
import StructureTree2 from "components/Tree/StructureTree2";
import CustomTypeahead from "components/CustomTypeahead";
import CustomTable from "components/CustomTable";
import moment from "moment";
import useQuery from "redux/useQuery";
import { selectDeepDown } from "redux/action/api";
import { filterData } from "redux/action/api";
import { removeParentEntries } from "redux/action/api";
import SearchBar from "components/SearchBar";
import useIsPhoneSize from "layouts/useIsPhoneSize";

function EcoMapping() {
  const [results, setResults] = useState();
  const [result1, setResult1] = useState();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [update, setUpdate] = useState(false);
  const toggle4 = () => setIsOpen(!isOpen);
  const [years, setYears] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const query = useQuery();
  const code = query.get("code");

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getSector({ type: "ECONOMIC SEGMENT" }, "select", (d) => {
      setRawData(d);

      // Log the original data
      console.log("Original Data:", JSON.stringify(d, null, 2));

      const modifiedResults = [...d].map((item) => ({
        ...item,
        children: item.children || [], // Ensure each item has a children array
      }));

      const modifiedResult1 = [...d];
      modifiedResult1.splice(0, 1);

      const ecoSegmentIndex = modifiedResults.findIndex(
        (item) => item.sub_head === "0000000000000000000"
      );

      if (ecoSegmentIndex !== -1) {
        modifiedResults[ecoSegmentIndex].sub_head = "";

        // Remove the eco segment from its original position
        const ecoSegment = modifiedResults.splice(ecoSegmentIndex, 1)[0];

        // Add it to the top of the array
        modifiedResults.unshift(ecoSegment);
      }

      // Log modified results before calling unflatten
      console.log(
        "Modified Results:",
        JSON.stringify(modifiedResults, null, 2)
      );

      setResults(modifiedResult1);

      try {
        const formattedData = unflatten(modifiedResults);
        // alert(JSON.stringify(formattedData));
        setResult1(formattedData);
      } catch (error) {
        console.error("Error in unflatten:", error);
      } finally {
        setLoading(false);
      }
    });
  }, []);

  let data = results?.map((i) => ({
    head: i.head,
    description: i.description,
    sub_head: i.sub_head,
  }));

  const rows = data || [];
  const navigate = useNavigate();
  const [modal3, setModal3] = useState(false);
  const [view, setView] = useState(false);

  const toggle3 = () => setModal3(!modal3);
  const [inputs, setInputs] = useState({
    head: "",
    description: "",
    remarks: "",
    type: "",
    mda_code: "",
    mda_name: "",
  });

  const handleChange = ({ target: { name, value } }) => {
    setInputs((p) => ({ ...p, [name]: value }));
  };

  const [result2, setResult2] = useState();
  const [damn, setDamn] = useState();
  const [edit, setEdit] = useState(false);
  const { addToast } = useToasts();
  const [rawData, setRawData] = useState([]);
  const [_filterData, setDataFilter] = useState([]);
  const initialState = {
    accessTo: [],
    role: "admin",
    description: [],
    mda_code: "",
    mda_name: "",
    year: moment().format("YYYY"),
    type: "ECONOMIC SEGMENT",
  };
  const [form, setForm] = useState(initialState);

  const handleCheckboxChange = (head, checked) => {
    const parentNode = selectDeepDown(rawData, head);
    if (!parentNode) {
      return;
    }

    const nodesToUpdate = [...parentNode];
    const _filterData1 = filterData(nodesToUpdate);
    setDataFilter(_filterData1);

    console.log(nodesToUpdate);

    if (checked) {
      setForm((prevForm) => ({
        ...prevForm,
        accessTo: [
          ...prevForm.accessTo,
          ...nodesToUpdate.map((node) => ({
            head: node.head,
            description: node.description,
            mda_name: prevForm.mda_name,
            mda_code: prevForm.mda_code,
            sub_head: node.sub_head,
            type: node.type,
          })),
        ],
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        accessTo: prevForm.accessTo.filter(
          (item) => !nodesToUpdate.some((node) => node.head === item.head)
        ),
      }));
    }
  };

  let getOnlyChildren = removeParentEntries(form?.accessTo);

  const handleSubmit = () => {
    if (code === "" || form.mda_code === "") {
      // alert("you must select a MDA before submitting");
      addToast("you must select a MDA before submitting", {
        appearance: "info",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
    } else {
      toggle4();
    }
  };
  const [results4, setResult4] = useState([]);
  useEffect(() => {
    getSector({ type: "mdas" }, "mdas", (d) => {
      setResult4(d);
    });
  }, []);
  let aheadData = results4?.map((i) => ({
    head: `${i.head}-${i.description}`,
    description: i.description,
    code: i.head,
  }));

  const yearsBeforeAndAfter = () => {
    const currentYear = moment().year();
    const years = [];
    for (let i = currentYear - 6; i <= currentYear + 3; i++) {
      years.push(i);
    }
    return years;
  };

  useEffect(() => {
    setYears(yearsBeforeAndAfter());
    getSector({ type: "mdas" }, "mdas", (d) => {
      setResult4(d);
    });
  }, []);
  const fields = [
    {
      title: "Head",
      value: "head",
    },
    {
      title: "Description",
      value: "description",
    },
  ];

  const mappingVerification = useCallback((mda_name) => {
    _fetchApi(
      `/mapping-verification/${mda_name}?type=ECONOMIC SEGMENT`,
      (results) => {
        if (results.success) {
          if (results.results.length) {
            setView(true);
            setForm((prev) => ({ ...prev, accessTo: results.results }));
            setUpdate(true);
          } else {
            setUpdate(false);
            setForm((prev) => ({ ...prev, accessTo: results.results }));
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const confirmSubmit = () => {
    setLoadingSubmit(true);
    _postApi(
      `/mda-mapping/${form.mda_name}?query_type=insert&year=${form.year}&mapping_code=${code}&type=${form.type}`,
      getOnlyChildren,
      (data) => {
        if (data.success) {
          setLoadingSubmit(false);
          setForm(initialState);
          setUpdate(false);
          setIsOpen(false);
          addToast("Saved Successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        }
      },
      (err) => {
        setLoadingSubmit(false);
        console.log(err);
        addToast("Saved Failed", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      }
    );
  };

  const confirmUpdate = () => {
    setLoadingSubmit(true);
    _postApi(
      `/delete-mapping?year=${form.year}&mapping_code=${code}`,
      {},
      (data) => {
        if (data.success) {
          _postApi(
            `/mda-mapping/${form.mda_name}?query_type=insert&year=${form.year}&mapping_code=${code}&type=${form.type}`,
            getOnlyChildren,
            (data) => {
              if (data.success) {
                addToast("Updated Successfully", {
                  appearance: "info",
                  autoDismiss: true,
                  autoDismissTimeout: 5000,
                });
                setLoadingSubmit(false);
                setForm(initialState);
                setIsOpen(false);
                setUpdate(false);
              }
            },
            (err) => {
              setLoadingSubmit(false);
              console.log(err);
              addToast("Updated Failed", {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 5000,
              });
            }
          );
        }
      },
      (err) => {
        setLoadingSubmit(false);
        console.log(err);
      }
    );
  };

  useEffect(() => {
    mappingVerification(form.mda_name);
  }, [form.mda_name]);

  const [searchQuery, setSearchQuery] = useState("");

  let datas = [];
  rawData?.forEach((item) => {
    if (
      item.description &&
      item.description?.toLowerCase().indexOf(searchQuery?.toLowerCase()) ===
        -1 &&
      item.head &&
      item.head?.toLowerCase().indexOf(searchQuery?.toLowerCase()) === -1
    )
      return;
    else datas.push(item);
  });

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSelectItem = (item) => {
    const updatedAvailableItems = rawData.filter((i) => i.head !== item.head);
    const updatedSelectedItems = [...form.accessTo, item];

    setRawData(updatedAvailableItems);
    setForm((prev) => ({ ...prev, accessTo: updatedSelectedItems }));
  };

  const handleRemoveItem = (item) => {
    const updatedSelectedItems = form.accessTo?.filter(
      (i) => i.head !== item.head
    );
    const updatedAvailableItems = [...rawData, item];

    setForm((prev) => ({ ...prev, accessTo: updatedSelectedItems }));
    setRawData(updatedAvailableItems);
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography
                  variant="h5"
                  color="white"
                  className="text-center"
                >
                  Economic Mapping
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <div className="row m-0 p-0">
                  {/* {JSON.stringify(rawData)} fijioii */}
                  <div
                    className="col-md-4 mb-3 m-3 mt-0"
                    style={{ marginLeft: "10px" }}
                  >
                    <CustomTypeahead
                      labelKey="head"
                      label="Select MDA"
                      options={aheadData}
                      onChange={(s) => {
                        if (s.length) {
                          navigate(`/economic-mapping?code=${s[0].code}`);
                          console.log(s);
                          setForm((p) => ({
                            ...p,
                            mda_code: s[0].code,
                            mda_name: s[0].description,
                          }));
                        }
                      }}
                    />
                  </div>

                  <div className="col-md-1 " />

                  <div className="col-md-6 mt-4">
                    <ButtonGroup className="mb-2 me-2">
                      <Button
                        color={view ? "dark" : "light"}
                        onClick={() => setView("new")}
                        className="sm-btn sm-padding"
                      >
                        View
                      </Button>
                      <Button
                        color={!view ? "dark" : "light"}
                        onClick={() => setView(false)}
                        className="sm-btn sm-padding"
                      >
                        Tree View
                      </Button>
                      <Button
                        color={view ? "dark" : "light"}
                        onClick={() => setView(true)}
                        className="sm-btn sm-padding"
                      >
                        Tree View
                      </Button>
                    </ButtonGroup>
                    {update ? (
                      <button
                        className="btn btn-primary px-3 mb-2 sm-btn sm-pading"
                        onClick={handleSubmit}
                        // onClick={toggle4}
                      >
                        Click to Confirm Update
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary px-3 mb-2 sm-btn sm-pading"
                        onClick={handleSubmit}
                        // onClick={toggle4}
                      >
                        Click to Confirm Submit
                      </button>
                    )}
                  </div>
                </div>

                {view === "new" ? (
                  <Container>
                    <Row>
                      <Col className="m-0 p-0">
                        <center>
                          <h6>Selected MDA&apos;s mapping here</h6>
                        </center>
                        <SearchBar
                          placeholder="Search By code or description"
                          filterText={searchQuery}
                          onFilterTextChange={handleSearchChange}
                        />
                        <Scrollbar height="50vh">
                          <ListGroup>
                            {datas?.map((item, index) => (
                              <ListGroupItem key={index}>
                                <span style={{ fontSize: "14px" }}>
                                  {item.description}({item.head})
                                </span>

                                <FaArrowRight
                                  size={20}
                                  onClick={() => handleSelectItem(item)}
                                  style={{
                                    float: "right",
                                    color: "blueviolet",
                                  }}
                                />
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                        </Scrollbar>
                      </Col>
                      <Col className="m-0 p-0">
                        <center>
                          <h6>Selected MDA&apos;s {form?.mda_name}</h6>
                        </center>

                        <Scrollbar height="50vh">
                          <ListGroup>
                            {form?.accessTo?.map((item, index) => (
                              <ListGroupItem key={index}>
                                <FaArrowLeft
                                  size={20}
                                  onClick={() => handleRemoveItem(item)}
                                  style={{
                                    float: "left",
                                    color: "red",
                                    marginRight: 5,
                                  }}
                                />
                                <span
                                  style={{
                                    float: "right",
                                    fontSize: "14px",
                                  }}
                                >
                                  {item.description}({item.head})
                                </span>
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                        </Scrollbar>
                      </Col>
                    </Row>
                  </Container>
                ) : (
                  <div className="row">
                    <div className={`col-md-${view ? 8 : 12}`}>
                      {loading && <Spinner />}
                      <Scrollbar>
                        <StructureTree2
                          treeData={result1}
                          addChild={(node) => {
                            setInputs((p) => ({
                              sub_head: node.head,
                              type: node.type,
                            }));
                            setDamn((p) => ({
                              ...p,
                              name: node.description,
                              type: node.type,
                              head: node.head,
                              hmm: "submit",
                              ids: node.head,
                              sub_head: node.sub_head,
                            }));
                            toggle3();
                          }}
                          editNode={(node, checked) => {
                            handleCheckboxChange(node.head, checked);
                          }}
                          update={update}
                          form={form}
                        />
                        {/* </center> */}
                        {/* <center><button className="btn btn-primary" onClick={handleSubmit}>Submit</button></center> */}
                      </Scrollbar>
                      {/* </div>
              <div className='col-md-8'> 
              <SearchBar placeholder='Search By code or description' filterText={searchQuery}  onFilterTextChange={handleSearchChange} />
              <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                /></div> */}
                    </div>
                    {view && (
                      <div className={`col-md-${view ? 4 : 0}`}>
                        <Scrollbar height="50vh">
                          <Grid item xs={12}>
                            <div>
                              <div
                                className="text-center"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                Selected MDA&apos;s {form?.mda_name}
                              </div>
                              <div>
                                <div
                                  style={{
                                    minHeight: "20vh",
                                    overflow: "scroll",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                >
                                  <CustomTable
                                    fields={fields}
                                    data={getOnlyChildren}
                                  />
                                </div>
                              </div>
                              <div>
                                {update ? (
                                  <Button
                                    color="primary"
                                    onClick={confirmUpdate}
                                  >
                                    {loadingSubmit ? (
                                      <Spinner size="sm" />
                                    ) : null}{" "}
                                    Confirm & Update
                                  </Button>
                                ) : (
                                  <Button
                                    color="primary"
                                    onClick={confirmSubmit}
                                  >
                                    {loadingSubmit ? (
                                      <Spinner size="sm" />
                                    ) : null}{" "}
                                    Confirm & Submit
                                  </Button>
                                )}
                              </div>
                            </div>
                          </Grid>
                        </Scrollbar>
                      </div>
                    )}
                  </div>
                )}
                {/* {JSON.stringify(results)} */}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Modal isOpen={isOpen} size="lg">
        <ModalHeader toggle={toggle4} className="text-center">
          Selected MDA&apos;s {form.mda_name}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              minHeight: "20vh",
              overflow: "scroll",
              margin: 0,
              padding: 0,
            }}
          >
            <CustomTable fields={fields} data={getOnlyChildren} />
          </div>
        </ModalBody>
        <ModalFooter>
          {update ? (
            <Button color="primary" onClick={confirmUpdate}>
              {loadingSubmit ? <Spinner size="sm" /> : null} Confirm & Update
            </Button>
          ) : (
            <Button color="primary" onClick={confirmSubmit}>
              {loadingSubmit ? <Spinner size="sm" /> : null} Confirm & Submit
            </Button>
          )}
          <Button color="secondary" onClick={toggle4}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </DashboardLayout>
  );
}

export default EcoMapping;
