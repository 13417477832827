import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CustomTree from "./CustomTree2"; // Ensure this imports correctly
import { toggleExpandedForAll } from "react-sortable-tree";
import { Input } from "reactstrap";

function StructureTree2({
  editNode = (f) => f,
  treeData = [],
  form = {},
  update = false,
}) {
  const [searchText, setSearchText] = useState("");
  const [expanded, setExpanded] = useState(true);
  const [filteredTreeData, setFilteredTreeData] = useState(treeData);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const expandedTreeData = treeData.map((node) => ({
      ...node,
      expanded,
    }));
    setFilteredTreeData(expandedTreeData);
  }, [expanded, treeData]);

  useEffect(() => {
    setIsLoading(true);
    if (!searchText) {
      setFilteredTreeData(treeData);
    } else {
      const filterTree = (data) =>
        data
          .map((node) => {
            if (
              node.head?.toLowerCase().includes(searchText?.toLowerCase()) ||
              node.description
                ?.toLowerCase()
                .includes(searchText?.toLowerCase())
            ) {
              return {
                ...node,
                expanded: true,
                children: node.children ? filterTree(node.children) : [],
              };
            }
            return null;
          })
          .filter((node) => node !== null);
      const filteredData = filterTree(treeData);
      setFilteredTreeData(filteredData);
    }
    setIsLoading(false);
  }, [searchText, treeData]);

  function expand(expanded) {
    setFilteredTreeData(
      toggleExpandedForAll({
        treeData,
        expanded,
      })
    );
  }

  useEffect(() => {
    if (update) {
      expand(true);
    }
  }, [update]);

  return (
    <>
      <div>
        <div className="col-md-6 m-5 mt-0 mb-1">
          <button
            onClick={() => expand(true)}
            className="btn btn-success sm-btn mt-2"
            style={{ marginRight: "10px" }}
          >
            Expand All
          </button>
          <button
            onClick={() => expand(false)}
            className="btn btn-success sm-btn mt-2"
          >
            Collapse All
          </button>
        </div>
        <div className="col-md-8">
          {isLoading ? (
            <div className="spinner-border text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <CustomTree
              generateNodeProps={({ node, path }) => {
                const isChecked =
                  form.accessTo?.findIndex((a) => a.head === node.head) !== -1;

                return {
                  title: (
                    <div
                      style={{
                        textTransform: node.children?.length
                          ? "uppercase"
                          : "capitalize",
                      }}
                    >
                      {`${node.head} - ${node.description}`}
                    </div>
                  ),
                  buttons: [
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      checked={isChecked}
                      onChange={(event) => {
                        editNode(node, event.target.checked);
                      }}
                      style={{ border: "2px solid blue" }}
                      key={path}
                    />,
                  ],
                };
              }}
              treeData={filteredTreeData}
            />
          )}
        </div>
      </div>
    </>
  );
}

StructureTree2.propTypes = {
  editNode: PropTypes.func,
  form: PropTypes.object,
  treeData: PropTypes.array,
  update: PropTypes.bool,
};

export default StructureTree2;
