/* eslint-disable react/prop-types */
import { PDFViewer } from "@react-pdf/renderer";
import SearchBar from "components/SearchBar";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Table, Input, Button, Spinner } from "reactstrap";
import { formatNumber } from "redux/action/api";
import { postApi } from "redux/action/api_tbeams";
import { NewRelisePdf } from "./NewRelisePdf";

export default function NewRecurrentMonthly({ steps = "3", form }) {
  const { addToast } = useToasts();
  const rec_type =
    steps === "3" ? "recurrent" : steps === "4" ? "critical" : null;
  const [approveData, setApproveData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const getApproveData = useCallback(() => {
    setLoading(true);
    postApi(`monthly-recurrent?query_type=select`, {
      rec_type,
    })
      .then((res) => {
        if (res.success) {
          const dataWithDisabledFlag = res.results.map((item) => ({
            ...item,
            ...form,
            mda_description: item.economic_name,
            mda_economic_code: item.economic_code,
            description: item.economic_name,
            mda_code: item.admin_code,
            mda_name: item.admin_name,
            projectType: rec_type,
            disabled: false,
          }));
          setApproveData(dataWithDisabledFlag);
        }
        setLoading(false);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [rec_type]);

  useEffect(() => {
    getApproveData();
  }, [getApproveData]);

  let data = [];

  approveData?.forEach((item) => {
    if (
      item.admin_name &&
      item.admin_name?.toLowerCase().indexOf(searchText?.toLowerCase()) === -1
    )
      return;
    else data.push(item);
  });

  const dataByMDA = {};
  data?.forEach((item) => {
    if (Object.keys(dataByMDA)?.includes(item.admin_code)) {
      dataByMDA[item.admin_code] = [...dataByMDA[item.admin_code], item];
    } else {
      dataByMDA[item.admin_code] = [item];
    }
  });

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleTableChange = (value, id) => {
    const newData = approveData.map((item) =>
      item.id === id ? { ...item, amount: value } : item
    );
    setApproveData(newData);
  };

  const handleParentCheck = (group) => {
    const newData = approveData.map((item) =>
      item.admin_code === group ? { ...item, disabled: !item.disabled } : item
    );
    setApproveData(newData);
  };

  const handleChildCheck = (id) => {
    const newData = approveData.map((item) =>
      item.id === id ? { ...item, disabled: !item.disabled } : item
    );
    setApproveData(newData);
  };

  let list = approveData
    .filter((item) => item.disabled === false)
    .map((items) => ({
      ...items,
      approved_amount: items.amount,
      narration: form.narration,
    }));

  const handleApi = () => {
    setIsLoading(true);
    form.sent_to_mda = form.sent_to.office || "";
    form.sent_to_office = form.sent_to.title || "";
    form.projectType = rec_type;
    postApi(`post_approval_collection`, { list, form })
      .then((data) => {
        if (data.success) {
          const _newData = approveData
            .map((i) => ({
              ...i,
              approval_no: data.approval_no,
              approved_amount: i.amount,
            }))
            .filter((item) => item.disabled === false);
          console.log(_newData, "LDLDLDL");
          setApproveData(_newData);
          addToast("submit success", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          setShowForm(false);
        }
      })
      .catch((err) => {
        alert(err);
        addToast(err, {
          appearance: "info",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const total = list?.reduce(
    (acc, item) => parseFloat(acc) + parseFloat(item?.amount),
    0
  );
  return (
    <div>
      {showForm ? (
        <>
          <div className="mt-2 row">
            <div className="col-md-6">
              <SearchBar
                placeholder="Search By MDA Name"
                filterText={searchText}
                onFilterTextChange={handleSearchChange}
              />{" "}
            </div>

            <div
              className="col-md-6"
              style={{ fontWeight: "bold", textAlign: "right" }}
            >
              Total Amount: {formatNumber(total)}
            </div>
          </div>
          {/* {JSON.stringify({ list })} */}
          {loading && <Spinner color="primary" />}
          <Table
            bordered
            striped
            className="mt-3"
            size="sm"
            style={{ fontSize: 12 }}
          >
            <thead>
              <tr>
                <th style={{ width: "55%" }}>Description</th>
                <th style={{ width: "20%" }}>Economic Code</th>
                <th style={{ width: "15%" }}>Amount</th>
                <th style={{ width: "10%" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(dataByMDA).map((group, grpIdx) => {
                const total = dataByMDA[group].reduce(
                  (a, b) => a + parseFloat(b.amount),
                  0
                );
                const isParentDisabled = dataByMDA[group].every(
                  (item) => item.disabled
                );
                return (
                  <React.Fragment key={grpIdx}>
                    <tr
                      style={{
                        backgroundColor: isParentDisabled ? "#f0f0f0" : "white",
                        opacity: isParentDisabled ? 0.5 : 1,
                      }}
                    >
                      <td style={{ fontWeight: "bold" }}>
                        {group}-{dataByMDA[group][0].admin_name}
                      </td>
                      <td></td>
                      <td style={{ fontWeight: "bold", textAlign: "right" }}>
                        N{formatNumber(total)}
                      </td>
                      <td>
                        <Input
                          type="checkbox"
                          style={{ fontSize: 20 }}
                          checked={isParentDisabled}
                          onChange={() => handleParentCheck(group)}
                        />
                      </td>
                    </tr>
                    {dataByMDA[group].map((item, index) => (
                      <tr
                        key={`${grpIdx}-${index}`}
                        style={{
                          backgroundColor: item.disabled ? "#f0f0f0" : "white",
                          opacity: item.disabled ? 0.5 : 1,
                        }}
                      >
                        <td>{item.economic_name}</td>
                        <td>{item.economic_code}</td>
                        <td style={{ textAlign: "right" }}>
                          <input
                            type="number"
                            maxLength={2}
                            className="form-control text-center"
                            value={item.amount || ""}
                            onChange={(e) => {
                              const value = e.target.value;
                              handleTableChange(value, item.id);
                            }}
                            disabled={item.disabled}
                          />
                        </td>
                        <td>
                          <Input
                            type="checkbox"
                            style={{ fontSize: 20 }}
                            checked={item.disabled}
                            onChange={() => handleChildCheck(item.id)}
                          />
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
          {list?.length ? (
            <center>
              <Button
                color="primary"
                onClick={handleApi}
                disabled={isLoading}
                style={{
                  marginRight: 10,
                }}
                className="px-5"
              >
                {isLoading ? <Spinner color="light" size="sm" /> : "Submit"}
              </Button>
            </center>
          ) : null}
        </>
      ) : (
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <NewRelisePdf selectedData={list} selecOffer={formatNumber} />
        </PDFViewer>
      )}
    </div>
  );
}
