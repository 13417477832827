/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import {
  Modal,
  Input,
  ModalHeader,
  ModalBody,
  Button,
  Label,
  Alert,
  Spinner,
} from "reactstrap";
import { expiredSetting } from "./helper";
import { year } from "helper";
import { useToasts } from "react-toast-notifications";
import { _postApi } from "redux/action/api";
import moment from "moment";
import { bg } from "redux/action/api";
import { 
  FaCalendarAlt, 
  FaMoneyBillWave, 
  FaUpload, 
  FaClock,
  FaCheckCircle,
  FaExclamationTriangle
} from "react-icons/fa";
import { postApi } from "redux/action/api_tbeams";
import ExcelUpload from "./UploadExcel";
import Scrollbar from "components/Tree/Scrollbar";

const today = new Date().toISOString().slice(0, 10);

function DateSetting({
  toggle,
  type,
  title = "Click to set Budget Proposal End Date",
  otherFuc = (f) => f,
}) {
  const [expired, setExpired] = useState({});
  const [isHovered, setIsHovered] = useState(false);

  function getExpiredSetting(type) {
    expiredSetting(
      { description: type },
      "select",
      (d) => {
        setExpired(d.results[0]);
      },
      (e) => {
        console.log(e);
      }
    );
  }

  useEffect(() => {
    getExpiredSetting(type);
  }, [type]);

  const getIcon = () => {
    switch(type) {
      case "Budget Proposal Start Date":
      case "Budget Proposal End":
        return <FaCalendarAlt size={24} />;
      case "Ceiling Start Date":
      case "Ceiling End Date":
        return <FaClock size={24} />;
      case "Opening Balance":
        return <FaMoneyBillWave size={24} />;
      case "Upload Budget":
        return <FaUpload size={24} />;
      default:
        return <FaCalendarAlt size={24} />;
    }
  };

  return (
    <div
      className="col-md-4 col-sm-6 mb-4"
      onClick={() => {
        toggle(type);
        otherFuc();
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ cursor: "pointer" }}
    >
      <div
        className="shadow p-4 rounded-3 h-100"
        style={{
          background: isHovered ? '#f8f9fa' : 'white',
          transition: 'all 0.3s ease',
          transform: isHovered ? 'translateY(-5px)' : 'none',
          border: '1px solid #e9ecef'
        }}
      >
        <div className="d-flex align-items-center mb-3">
          <div className="me-3" style={{ color: '#1A73E8' }}>
            {getIcon()}
          </div>
          <div>
            <small className="text-muted d-block">
              {expired?.end_date || today}
            </small>
            <h6 className="mb-0 mt-1 fw-bold" style={{ color: '#344767' }}>{title}</h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function OtherSetup() {
  const _form = { description: "", end_date: today };
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [form, setForm] = useState(_form);
  const [status, setStatus] = useState(false);
  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const disableCond =
    moment().format("YYYY") === moment(form.end_date).format("YYYY");
  const toggle = (type) => {
    setModal(!modal);
    setForm((p) => ({ ...p, description: type }));
  };

  const toggle2 = () => {
    setModal2(!modal2);
    // setForm((p) => ({ ...p, description: type }));
  };

  const toggle4 = () => {
    setModal4(!modal4);
    // setForm((p) => ({ ...p, description: type }));
  };

  const createNewBudget = () => {
    _postApi(
      "/callExpiredSettingAndCreateNext-budget",
      {},
      (res) => {
        console.log(res);
        toggle(form.description);
        addToast("Successfully", {
          appearance: "info",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      },
      (err) => {
        console.log(err);
        addToast("Failed", {
          appearance: "warning",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      }
    );
  };

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  let query = status ? "update" : "insert";

  const handleSubmit = () => {
    setLoading(true);
    expiredSetting(
      form,
      query,
      () => {
        setLoading(false);
        toggle(form.description);
        addToast("Saved Successfully", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      },
      (e) => {
        setLoading(false);
        console.log(e);
        addToast("Save Failed", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      }
    );
  };

  const handleSubmit2 = () => {
    postApi(`opening_balance?query_type=create`, form)
      .then((data) => {
        console.log("data", data);
        if (data.results) {
          addToast("Form submitted successfully!", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        addToast("Fail to Fetch", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      })
      .finally(() => {});
  };

  useEffect(() => {
    expiredSetting(
      { end_date: year, description: form.description },
      "checked",
      (res) => {
        // alert(JSON.stringify(res.results))
        if (res.results.length) {
          setStatus(true);
          setForm(res.results[0]);
        } else {
          setStatus(false);
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }, [year, form.description, status]);

  return (
    <DashboardLayout>
      <MDBox pt={3} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                className="d-flex align-items-center justify-content-between"
              >
                <MDTypography variant="h5" color="white">
                  Other Setup
                </MDTypography>
              </MDBox>
              
              <MDBox pt={4} p={3}>
                <div className="row g-4">
                  <DateSetting
                    form={form}
                    toggle={() => toggle("Budget Proposal Start Date")}
                    type="Budget Proposal Start Date"
                    title="Create New Budget Proposal"
                    otherFuc={() => setDisplay(true)}
                  />

                  <DateSetting
                    form={form}
                    toggle={() => toggle("Proposed Budget End Date")}
                    type="Budget Proposal End"
                    otherFuc={() => setDisplay(false)}
                  />

                  <DateSetting
                    form={form}
                    toggle={() => toggle("Ceiling Start Date")}
                    type="Ceiling Start Date"
                    title="Set Ceiling Start Date"
                    otherFuc={() => setDisplay(false)}
                  />

                  <DateSetting
                    form={form}
                    toggle={() => toggle("Ceiling End Date")}
                    type="Ceiling End Date"
                    title="Set Ceiling End Date"
                    otherFuc={() => setDisplay(false)}
                  />

                  <DateSetting
                    form={form}
                    toggle={() => toggle2("Opening Balance")}
                    type="Opening Balance"
                    title="Set Opening Balance"
                    otherFuc={() => setDisplay(false)}
                  />

                  <DateSetting
                    form={form}
                    toggle={() => toggle4("Opening Balance")}
                    type="Upload Budget"
                    title="Upload Budget"
                    otherFuc={() => setDisplay(false)}
                  />
                </div>
              </MDBox>

              <Modal
                isOpen={modal}
                toggle={() => toggle(form.description)}
                size="md"
              >
                <ModalHeader className="bg-light">
                  <div className="d-flex align-items-center">
                    {form.description}
                  </div>
                </ModalHeader>
                {display ? (
                  <div className="p-4">
                    <Alert color="info" className="d-flex align-items-center">
                      <FaExclamationTriangle className="me-2" size={20} />
                      <small>
                        Please note that this action can only be performed once per year. 
                        If the button is disabled, please contact your administrator.
                      </small>
                    </Alert>
                    <div className="text-center mt-4">
                      <Button
                        color="primary"
                        onClick={() => createNewBudget()}
                        disabled={disableCond || loading}
                        className="px-4 py-2"
                      >
                        {loading ? (
                          <Spinner size="sm" className="me-2" />
                        ) : (
                          <FaCheckCircle className="me-2" />
                        )}
                        Create New Budget Proposal
                      </Button>
                    </div>
                  </div>
                ) : (
                  <ModalBody className="p-4">
                    <div className="mb-3">
                      <Label className="form-label">Set Date</Label>
                      <Input
                        type="date"
                        name="end_date"
                        value={form.end_date}
                        onChange={handleChange}
                        className="form-control"
                      />
                    </div>

                    <div className="mb-4">
                      <Label className="form-label">Description</Label>
                      <Input
                        name="description"
                        value={form.description}
                        onChange={handleChange}
                        placeholder="Enter description"
                        className="form-control"
                      />
                    </div>

                    <Button
                      color="primary"
                      onClick={handleSubmit}
                      disabled={loading}
                      className="w-100"
                    >
                      {loading ? (
                        <Spinner size="sm" className="me-2" />
                      ) : status ? (
                        "Update"
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </ModalBody>
                )}
              </Modal>

              <Modal
                isOpen={modal2}
                toggle={() => toggle2()}
                size="md"
              >
                <ModalHeader className="bg-light">Opening Balance Setup</ModalHeader>
                <ModalBody className="p-4">
                  <div className="mb-3">
                    <Label className="form-label">Set Date</Label>
                    <Input
                      type="date"
                      name="end_date"
                      value={form.end_date}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">Opening Balance</Label>
                    <Input
                      name="opening_balance"
                      value={form.opening_balance}
                      onChange={handleChange}
                      placeholder="Enter opening balance"
                      className="form-control"
                    />
                  </div>

                  <div className="mb-4">
                    <Label className="form-label">Closing Balance</Label>
                    <Input
                      name="closing_bal"
                      value={form.closing_bal}
                      onChange={handleChange}
                      placeholder="Enter closing balance"
                      className="form-control"
                    />
                  </div>

                  <Button
                    color="primary"
                    onClick={handleSubmit2}
                    disabled={loading}
                    className="w-100"
                  >
                    {loading ? (
                      <Spinner size="sm" className="me-2" />
                    ) : status ? (
                      "Update"
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </ModalBody>
              </Modal>

              <Modal
                isOpen={modal4}
                toggle={() => toggle4()}
                size="xl"
              >
                <ModalHeader className="bg-light">Upload Budget</ModalHeader>
                <ModalBody className="p-4">
                  <Scrollbar>
                    <ExcelUpload />
                  </Scrollbar>
                </ModalBody>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
